import React from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

import Div from '@jumbo/shared/Div';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: '40%',
        transform: 'translateY(-50%)',
        p: theme => theme.spacing(4),
      }}>
      <Div sx={{ display: 'inline-flex', mb: 3 }}>
        <img
          src='/images/apps/undraw_page_not_found.svg'
          alt='404'
          width={380}
        />
      </Div>
      <Typography
        align={'center'}
        component={'h2'}
        variant={'h1'}
        color={'text.secondary'}
        mb={3}>
        您正在尋找的頁面可能已被移動、更名或暫時不可用。
      </Typography>

      <Button variant='contained' onClick={() => navigate('/')}>
        返回 AIMochi
      </Button>
    </Div>
  );
};

export default Error404;
