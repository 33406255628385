import Vconsole from 'vconsole';
import detectDeviceType from './detectDeviceType';

let vConsole = null;

const isPc = () => {
  const userAgentInfo = navigator.userAgent;
  const Agents = ["Android", "iPhone",
      "SymbianOS", "Windows Phone",
      "iPad", "iPod"];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
      }
  }
  return flag;
}

const isLiffPage = window.location.href.includes('/liff/');

if (process.env.REACT_APP_LIFF_ENV === 'development' && detectDeviceType() !== 'Desktop') {
  console.log('vConsole is enabled')
  vConsole = new Vconsole();
  vConsole.setSwitchPosition(0, 200)
}

export default vConsole;