import { useState } from 'react';

import { Modal, Box, TextField, Backdrop, Fade, Button } from '@mui/material';

const PasswordModal = ({ confirmPassword, isOpen, handleClose }) => {
  const [password, setPassword] = useState('');

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    const result = confirmPassword(password);
    if (!result) return;
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,

            borderRadius: 1,
            width: 320,
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              type='password'
              fullWidth
              placeholder='請輸入密碼'
              value={password}
              onChange={handlePasswordChange}
              size='small'
            />
            <Button variant='contained' sx={{ ml: 2 }} onClick={handleSubmit}>
              確認
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PasswordModal;
