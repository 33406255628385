import React from 'react';
import LiffStatus from 'app/pages/Liff/Status';
import LiffTutorial from 'app/pages/Liff/Tutorial';
import LiffUpload from 'app/pages/Liff/UpLoad';
import LiffLoginSuccess from 'app/pages/Liff/LoginSuccess';

const liffRoutes = [
  {
    path: '/liff/status',
    element: <LiffStatus />,
  },
  {
    path: '/liff/tutorial',
    element: <LiffTutorial />
  },
  {
    path: '/liff/upload',
    element: <LiffUpload />,
  },
  {
    path: '/liff/login/success',
    element: <LiffLoginSuccess />,
  }
];

export default liffRoutes;
