import Immutable from 'immutable';

import * as __ from '../types/status';
import moment from 'moment';

const INIT_STATE = Immutable.OrderedMap({
  type: 'undone',
  start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
});

const statusReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case __.CNG_STATUS_TYPE:
      return _.set('type', payload.status);
    case __.CNG_START_DATE:
      return _.set('start_date', payload.start_date);
    case __.CNG_END_DATE:
      return _.set('end_date', payload.end_date);
    default:
      return _;
  }
};

export default statusReducer;