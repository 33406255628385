import Immutable from 'immutable';

import { CHATGPT_ASK, CHATGPT_ASK_REPLY } from '../types/chat';
import fakeMsg from 'app/pages/ChatGpt/fakeMsg';

const INIT_STATE = Immutable.OrderedMap({
  message: fakeMsg,
  isLoading: false,
});

const chatgptReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHATGPT_ASK:
      return _.set('message', [..._.get('message'), payload.question]).set(
        'isLoading',
        true
      );
    case CHATGPT_ASK_REPLY:
      return _.set('message', [..._.get('message'), payload.reply]).set(
        'isLoading',
        false
      );
    default:
      return _;
  }
};

export default chatgptReducer;
