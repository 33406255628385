import React from 'react';
import ChatGptPage from 'app/pages/ChatGpt';

const chatgptRoutes = [
  {
    path: '/askChatGpt',
    element: <ChatGptPage />,
  },
];

export default chatgptRoutes;
