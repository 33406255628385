import { useSnackbar } from 'notistack';

const useAlert = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessAlert = (message, duration = 3000) => {
    return enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: duration,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  const showErrorAlert = (message, duration = 3000) => {
    return enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: duration,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }

  return { showSuccessAlert, showErrorAlert };
};

export default useAlert;
