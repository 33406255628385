import Div from '@jumbo/shared/Div';
import {
  Modal,
  Backdrop,
  Fade,
  Typography,
  Box,
  Stack,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material';

const ConfirmModal = ({
  isOpen,
  isLoading,
  handleClose,
  handleSubmit,
  title,
  content,
  okButtonDisabled,
  cancelButtonDisabled,
  okText = '確認',
  cancelText = '取消',
  children,
  type = 'normal'
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={!isLoading && handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <Div
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 360,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
          <Typography
            id='transition-modal-title'
            variant='h1'
            sx={{ textAlign: 'center', mb: 3, fontWeight: 700 }}>
            {title}
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Typography
            id='transition-modal-title'
            variant='h4'
            component='h2'
            sx={{ mb: 4, textAlign: 'center' }}>
            {content}
          </Typography>
          {children}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack flexDirection='row' gap={4}>
              {!okButtonDisabled && (
                <Button
                  startIcon={isLoading && <CircularProgress size={12} color='inherit' />}
                  disabled={isLoading}
                  variant='contained'
                  color={type === 'danger' ? 'error' : 'primary'}
                  onClick={handleSubmit}>
                  {okText}
                </Button>
              )}
              {!cancelButtonDisabled && (
                <Button disabled={isLoading} variant='outlined' onClick={handleClose}>
                  {cancelText}
                </Button>
              )}
            </Stack>
          </Box>
        </Div>
      </Fade>
    </Modal>
  );
};

export default ConfirmModal;
