import { deleteRequest } from 'app/services';
import * as __ from '../types/status';

export const cngStatusType = status => {
  return {
    type: __.CNG_STATUS_TYPE,
    payload: {
      status,
    },
  };
};

export const cngStartDate = start_date => {
  return {
    type: __.CNG_START_DATE,
    payload: {
      start_date,
    },
  };
};

export const cngEndDate = end_date => {
  return {
    type: __.CNG_END_DATE,
    payload: {
      end_date,
    },
  };
};

export const delNoteStatus = (uId, nId) => {
  const url = `/api/v1/users/${uId}/notes/${nId}`;

  return async dispatch => {
    dispatch({ type: __.DEL_NOTE_STATUS });
    await deleteRequest(url);
    return dispatch(delNoteStatusDone(nId));
  };
};

export const delNoteStatusDone = nId => {
  return { type: __.DEL_NOTE_STATUS_DONE, payload: { nId } };
};
