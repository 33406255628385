import React from 'react';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Div from '@jumbo/shared/Div';

import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import { useTranslation } from 'react-i18next';

import AssignmentIcon from '@mui/icons-material/Assignment';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goSettingPage = page => {
    return navigate(page, { replace: true });
  };

  return (
    <Div
      sx={{
        py: 2,
        px: { lg: 6, xs: 4 },
        borderTop: 1,
        borderColor: 'divider',
        backgroundColor: '#Fff',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 999,
      }}>
      <Div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          width='100%'>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: '60px',
            }}>
            <OtherHousesIcon
              sx={{ color: '#202020' }}
              component='svg'
              onClick={() => goSettingPage('/')}
            />
            <Typography>{t('FOOTER_HOME')}</Typography>
          </Box>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: '60px',
            }}>
            <DriveFolderUploadIcon
              sx={{ color: '#202020' }}
              component='svg'
              onClick={() => goSettingPage('/liff/upload')}
            />
            <Typography>上傳檔案</Typography>
          </Box>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: '60px',
            }}>
            <AssignmentIcon
              sx={{ color: '#202020' }}
              component='svg'
              onClick={() => goSettingPage('/note/status')}
            />
            <Typography>任務進度</Typography>
          </Box>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: '60px',
            }}>
            <PersonIcon
              sx={{ color: '#202020' }}
              component='svg'
              onClick={() => goSettingPage('/setting')}
            />
            <Typography>{t('FOOTER_SETTING')}</Typography>
          </Box>
        </Stack>
      </Div>
    </Div>
  );
};

export default Footer;
