import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const DateArea = ({ created_at }) => {
  const { t } = useTranslation();

  const date = moment.unix(created_at).format('YYYY-MM-DD');
  return (
    <Grid
      item
      container
      md={12}
      xs={12}
      sx={{
        padding: '8px 16px',
      }}>
      <Grid item xs={2.6} md={1.2} lg={1.2} xl={0.75} sx={{ minWidth: 100 }}>
        <Box
          component='div'
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <AccessTimeIcon
            sx={{
              mr: 0.5,
              position: 'relative',
              top: '-1px',
              fontSize: 18,
            }}
          />

          <Typography variant='subtitle2'>{t('NOTE_CREATED_AT')}</Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={8}>
        <Typography variant='subtitle2' sx={{ color: '#C5C9CB' }}>
          {date}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(DateArea);
