const statusSchedule = {
  INIT: {
    text: '初始化',
    value: 0,
    color: '#37373C',
  },
  TRANSCODING: {
    text: '轉換中',
    value: 10,
    color: '#37373C',
  },
  TRANSCODED: {
    text: '轉換完成',
    value: 30,
    color: '#37373C',
  },
  TRANSFORMING: {
    text: '分析中',
    value: 50,
    color: '#37373C',
  },
  TRANSFORMED: {
    text: '分析完成',
    value: 70,
    color: '#37373C',
  },
  DONE: {
    text: '完成',
    value: 100,
    color: 'green',
  },
  FAILED: {
    text: '失敗',
    value: 0,
    color: 'red',
  },
};

export default statusSchedule;