import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

const SearchFilter = ({ handleDatePickerOpen }) => {
  const [anChorEl, setAnChorEl] = useState(null);
  const open = Boolean(anChorEl);

  const handleClick = event => {
    setAnChorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnChorEl(null);
  };

  const handleMenuClick = cb => {
    handleClose();
    cb();
  };

  const itemDict = [
    {
      key: 'date',
      name: '時間區間',
      callback: () => handleMenuClick(handleDatePickerOpen),
    },
  ];

  return (
    <>
      <IconButton onClick={handleClick} sx={{ backgroundColor: 'inherit' }}>
        <TuneIcon />
      </IconButton>
      <Menu anchorEl={anChorEl} open={open} onClose={handleClose}>
        {itemDict.map(item => (
          <MenuItem key={item.key} onClick={item.callback}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SearchFilter;
