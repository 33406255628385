import axios from 'axios';
import {
  FCH_LABELLIST,
  FCH_LABELLIST_DONE,
  CREATE_LABEL,
  CREATE_LABEL_DONE,
} from '../types/label';
import moment from 'moment';
import { updNoteLabel } from './note';

export const fchLabelList = uid => {
  const url = `/api/v1/users/${uid}/labels`;
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr).token;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      authorization: token,
    },
  };

  return async dispatch => {
    try {
      dispatch({ type: FCH_LABELLIST });
      const res = await axios.get(url, config);
      dispatch({ type: FCH_LABELLIST_DONE, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const createLabel = (uid, name, nid, lists) => {
  const url = `/api/v1/users/${uid}/labels`;
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr).token;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      authorization: token,
    },
  };

  return async dispatch => {
    try {
      dispatch({ type: CREATE_LABEL });
      const res = await axios.post(url, { name }, config);
      const created_at = moment().unix();
      const id = res.data.payload.id;
      dispatch(createLabelDone({ id, user_id: uid, created_at, name }));
      const labels = [...lists, id];
      dispatch(updNoteLabel(uid, nid, labels))
      return;
    } catch (error) {
      console.log(error);
    }
  };
};

export const createLabelDone = label => {
  return { type: CREATE_LABEL_DONE, payload: label };
};
