import { generateHTML, generateJSON } from '@tiptap/core';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Image from '@tiptap/extension-image';
import TLink from '@tiptap/extension-link';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Paragraph from '@tiptap/extension-paragraph';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Bold from '@tiptap/extension-bold';
import TurndownService from 'turndown';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Blockquote from '@tiptap/extension-blockquote';
import CodeBlock from '@tiptap/extension-code-block';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import Code from '@tiptap/extension-code';

const genListType = [
  Document,
  Color,
  Highlight,
  HorizontalRule.configure({
    HTMLAttributes: { class: 'novel-editor-hr' },
  }),
  Image.configure({ HTMLAttributes: { class: 'novel-editor-image' } }),
  TLink,
  TaskItem,
  TaskList,
  TextStyle,
  Underline,
  Paragraph.configure({ HTMLAttributes: { class: 'novel-editor-p' } }),
  Text,
  Bold,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  Code,
];

export const genHTML = note => {
  if (!note.html) {
    let html = note.content
      .split('\n')
      .filter(Boolean)
      .map(line => `<p>${line}</p>`)
      .join('');

    const ishasImage = note.content.includes(note.fileurl);
    if (note.source.type === 'image' && !ishasImage) {
      const imageUrl = `<img src="${note.fileurl}" />`;
      html = `${html}${imageUrl}`;
    }

    return html;
  }

  const jsonObj = {
    type: 'doc',
    content: [],
  };

  JSON.parse(note.html).content.forEach(c => {
    jsonObj.content.push(c);
  });

  const foundItem = jsonObj.content.find(
    item =>
      item.type === 'image' && item.attrs && item.attrs.src === note.fileurl
  );
  if (note.source.type === 'image' && !foundItem) {
    const imageObj = {
      type: 'image',
      attrs: {
        src: note.fileurl,
        alt: 'image',
        title: null,
        HTMLAttributes: {
          class: 'novel-editor-image',
        },
      },
    };

    jsonObj.content.push(imageObj);
  }

  return generateHTML(jsonObj, genListType);
};

export const genContent = note => {
  const html = genHTML(note);

  return generateJSON(html, genListType);
};

export const genTxt = note => {
  const file = new Blob([note], {
    type: 'text/plain;charset=utf-8',
  });
  const url = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = 'note.txt';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const genMarkDown = note => {
  const turndownService = new TurndownService();
  const url = `${window.location.origin}${note.fileurl}`;

  const downloadMarkdown = markdown => {
    const element = document.createElement('a');
    const file = new Blob([markdown], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = 'note.md';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  };

  if (!note.html) {
    let html = note.content
      .split('\n')
      .filter(Boolean)
      .map(line => `<p>${line}</p>`)
      .join('');

    if (note.source.type === 'image') {
      const imageUrl = `<img src="${url}" />`;
      html = `${html}${imageUrl}`;
    }

    const markdown = turndownService.turndown(html);

    // return downloadMarkdown(markdown);
    return markdown;
  }

  const json = JSON.parse(note.html);
  const formatHtml = generateHTML(json, genListType);

  const markdown = turndownService.turndown(formatHtml);

  // return downloadMarkdown(markdown);
  return markdown;
};
