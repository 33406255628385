import { Card, Stack, IconButton, Typography } from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import ScrollTop from 'app/hooks/useScrollTrigger';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import React from 'react';

const FloatController = props => {
  const {
    playType,
    isPlay,
    formatHour,
    formatMinute,
    formatSecond,
    handlePlay,
    handlePause,
    onForwardClick,
    onBackwardClick,
    handleBackToTop,
  } = props;

  return (
    <ScrollTop {...props} eleId='waveform'>
      <Card>
        <Stack flexDirection='row' alignItems='center'>
          {playType === 'audio' && (
            <IconButton onClick={onBackwardClick}>
              <Replay5Icon sx={{ fontSize: '24px' }} />
            </IconButton>
          )}

          {isPlay ? (
            <IconButton onClick={handlePause}>
              <PauseIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          ) : (
            <IconButton onClick={handlePlay}>
              <PlayArrowIcon sx={{ fontSize: '24px' }} />
            </IconButton>
          )}

          <Typography variant='span' sx={{ m: 0, fontSize: '14px', mx: 1 }}>
            {`${formatHour}:${formatMinute}:${formatSecond}`}
          </Typography>

          {playType === 'audio' && (
            <IconButton onClick={onForwardClick}>
              <Forward5Icon sx={{ fontSize: '24px' }} />
            </IconButton>
          )}

          <IconButton onClick={handleBackToTop}>
            <ArrowUpwardIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </Card>
    </ScrollTop>
  );
};

export default React.memo(FloatController);
// export default FloatController;
