import Div from '@jumbo/shared/Div';
import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  List,
  ListItemText,
  Typography,
  TextField,
  Paper,
  IconButton,
  InputBase,
  Box,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { askChatGpt } from 'app/redux/actions/chat';

const ChatGptPage = () => {
  const dispatch = useDispatch();

  const [question, setQuestion] = useState('');

  const message = useSelector(({ chatgpt }) => chatgpt.get('message'));
  const userId = useSelector(({ app }) => app.user.id);

  const GptMsgBox = msg => {
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar alt='AIMochi' src='/images/logo-main.png' />
        </ListItemAvatar>
        <ListItemText key='13'>
          <Typography
            component='span'
            sx={{ border: '1px solid #ccc', padding: 1, borderRadius: 1 }}>
            {msg}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  };

  const UserMsgBox = msg => {
    return (
      <ListItem>
        <ListItemText
          sx={{ marginRight: 2 }}
          key='12'
          align='right'>
          <Typography
            component='span'
            sx={{ border: '1px solid #ccc', padding: 1, borderRadius: 1 }}>
            {msg}
          </Typography>
        </ListItemText>

        <ListItemAvatar>
          <Avatar alt='User' />
        </ListItemAvatar>
      </ListItem>
    );
  };

  const handleChange = e => {
    setQuestion(e.target.value);
  };

  const handleAskChatGpt = () => {
    dispatch(askChatGpt(userId, question));
    setQuestion('');
  };

  return (
    <Div>
      <Grid>
        <List>
          {message.map(msg => {
            if (msg.character === 'user') {
              return UserMsgBox(msg.message);
            } else {
              return GptMsgBox(msg.message);
            }
          })}
        </List>
      </Grid>
      <Paper
        component='form'
        sx={{
          width: '100%',
          padding: '12px 16px',
          position: 'fixed',
          bottom: 0,
          borderTop: '1px solid #ccc',
        }}>
        <InputBase
          size='medium'
          sx={{
            width: '89%',
            border: '1px solid #ccc',
            borderRadius: 1,
            height: 40,
            fontSize: 16,
            padding: 1
          }}
          placeholder='請輸入問題'
          value={question}
          onChange={handleChange}
        />
        <IconButton sx={{ padding: 0 }} onClick={handleAskChatGpt}>
          <KeyboardArrowRightIcon color='primary' sx={{ fontSize: 36 }} />
        </IconButton>
      </Paper>
    </Div>
  );
};

export default ChatGptPage;
