import Div from '@jumbo/shared/Div';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import srt2Parser from 'srt-parser-2';
import SRTCard from './SRTCard';
import VideoPlayer from './VideoPlayer';
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
} from '@mui/material';
import Loading from '../Loading';
import { useDispatch } from 'react-redux';
import { updNoteContent } from 'app/redux/actions/note';
import { debounce } from 'lodash';
import Immutable from 'immutable';
import AudioH5Player from './AudioH5Player';

const parser = new srt2Parser();

const MediaPlayer = ({
  userId,
  noteId,
  content,
  url,
  isImmersive,
  handleSwitchImmersive,
}) => {
  const scrollToRef = useRef(null);

  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const [isPlay, setIsPlay] = useState(false);
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateTime = currentTime => {
    setCurrentTime(currentTime);
  };

  const handleCheckReady = isReady => {
    setIsReady(isReady);
  };

  const handlePlay = () => {
    setIsPlay(true);

    if (scrollToRef.current && !isImmersive) {
      scrollToRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'center',
      });
    }
  };

  const handlePause = () => {
    setIsPlay(false);
  };

  const handleSeek = time => {
    console.log('time', time);
    const index = Immutable.fromJS(dataSet).findIndex(
      data => time >= data.get('startSeconds') && time <= data.get('endSeconds')
    );

    setScrollToIndex(index);
  };

  const playType = useMemo(
    () => (url.includes('?namespace=files') ? 'audio' : 'video'),
    [url]
  );

  const urlMemo = useMemo(() => url, [url]);
  const isImmersiveMemo = useMemo(() => isImmersive, [isImmersive]);
  const playTypeMemo = useMemo(() => playType, [playType]);
  const isPlayMemo = useMemo(() => isPlay, [isPlay]);
  const isReadyMemo = useMemo(() => isReady, [isReady]);

  const formatSecond = useMemo(() => {
    const second = ('00' + Math.floor(currentTime % 60)).slice(-2);

    return second;
  }, [currentTime]);

  const formatMinute = useMemo(
    () => ('00' + Math.floor(currentTime / 60)).slice(-2),
    [currentTime]
  );

  const formatHour = useMemo(() => {
    return ('00' + Math.floor(currentTime / 3600)).slice(-2);
  }, [currentTime]);

  const handleBackToTop = () => {
    setScrollToIndex(0);
    if (window) {
      const delay = setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 200);

      return () => clearTimeout(delay);
    }
  };

  const handleChangeText = e => {
    const newContentArray = [...dataSet];
    const index = newContentArray.findIndex(item => item.id === e.target.id);
    newContentArray[index].text = e.target.value;
    setDataSet(newContentArray);
    const newContent = parser.toSrt(newContentArray);
    debounceUpdateText(userId, noteId, newContent);
  };

  const debounceUpdateText = useCallback(
    debounce((userId, noteId, newContent) => {
      dispatch(updNoteContent(userId, noteId, newContent));
    }, 500),
    []
  );

  const handleParseSRT = async srtContent => {
    setIsLoading(true);
    try {
      const parsedContent = await parser.fromSrt(srtContent);
      setDataSet(parsedContent);
    } catch (error) {
      console.error('Error parsing SRT:', error);
      // 在實際應用中，你可以根據需要進行錯誤處理
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeImmersive = (e, checked) => {
    handleSwitchImmersive(e, checked);
    handleScrollToIndex();
  };

  const findSubtitleIndexByTime = (subtitles, currentTime) => {
    let low = 0;
    let high = subtitles.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const { startSeconds, endSeconds } = subtitles[mid];

      if (currentTime >= startSeconds && currentTime <= endSeconds) {
        return mid;
      } else if (currentTime < startSeconds) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }

    // If currentTime is not within any subtitle time range, return -1 or handle appropriately.
    return -1;
  };

  const handleScrollToIndex = () => {
    const index = findSubtitleIndexByTime(dataSet, currentTime);
    setScrollToIndex(index);
  };

  useEffect(() => {
    if (!content) return;
    handleParseSRT(content);
  }, [content]);

  useEffect(() => {
    handleScrollToIndex(dataSet, currentTime);
  }, [currentTime]);

  return (
    <Div id='mediaform' sx={{ p: 2, maxWidth: '100%' }}>
      <Card
        sx={{
          mb: 2,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          zIndex: 99,
        }}>
        <CardHeader
          sx={{ width: '100%', p: 0, mb: 1 }}
          action={
            <FormControlLabel
              control={
                <Switch
                  checked={isImmersive}
                  onChange={handleChangeImmersive}
                />
              }
              label='開啟劇院模式'
              labelPlacement='start'
            />
          }
        />

        {/* {isReady && (
          <CardContent
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              top: 0,
              left: 0,
              width: '100%',
              // height: '100%',
              zIndex: 9999,
              border: '1px solid red'
            }}>
            <Loading />
          </CardContent>
        )} */}
        <CardContent
          sx={{
            width: '100%',
            p: 0,
            display: 'flex',
            justifyContent: 'center',
            '&:last-child': { pb: 0 },
          }}>
          {playTypeMemo === 'video' && (
            <VideoPlayer
              url={urlMemo}
              isPlay={isPlayMemo}
              isReady={isReadyMemo}
              isImmersive={isImmersiveMemo}
              formatHour={formatHour}
              formatMinute={formatMinute}
              formatSecond={formatSecond}
              handleUpdateTime={handleUpdateTime}
              handleCheckReady={handleCheckReady}
              handlePlay={handlePlay}
              handlePause={handlePause}
              handleBackToTop={handleBackToTop}
            />
          )}
          {playTypeMemo === 'audio' && (
            <AudioH5Player
              url={urlMemo}
              isPlay={isPlayMemo}
              isReady={isReadyMemo}
              isImmersive={isImmersiveMemo}
              formatHour={formatHour}
              formatMinute={formatMinute}
              formatSecond={formatSecond}
              handleUpdateTime={handleUpdateTime}
              handlePlay={handlePlay}
              handlePause={handlePause}
              handleCheckReady={handleCheckReady}
              handleBackToTop={handleBackToTop}
            />
          )}
        </CardContent>
      </Card>

      {isLoading && (
        <CardContent
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            top: 0,
            left: 0,
            width: '100%',
            // height: '100%',
            zIndex: 9999,
            border: '1px solid red',
          }}>
          <Loading />
        </CardContent>
      )}
      {!isLoading && (
        <SRTCard
          scrollToRef={scrollToRef}
          scrollToIndex={scrollToIndex}
          isImmersive={isImmersiveMemo}
          dataSet={dataSet}
          currentTime={Number(currentTime)}
          isPlay={isPlay}
          handleChangeText={handleChangeText}
        />
      )}
    </Div>
  );
};

export default React.memo(MediaPlayer);
