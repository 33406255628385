import { Box, LinearProgress, Typography, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import statusSchedule from 'app/utils/statusSchedule';

import { fchNote, fchNoteTask } from 'app/redux/actions/search';
import useLiff from 'app/hooks/useLiff';
import Loading from 'app/shared/Loading';
import useAlert from 'app/hooks/useAlert';

const LiffStatus = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { showSuccessAlert } = useAlert();

  const taskId = searchParams.get('taskId');
  const [status, setStatus] = useState('INIT');
  const [noteId, setNoteId] = useState(searchParams.get('noteId'));
  const [remainingMins, setRemainingMins] = useState(0);

  const liffId =
    process.env.REACT_APP_LIFF_ENV === 'development'
      ? '1661300442-nrE1EDKE'
      : '2000230437-Arblxbqx';

  const { isReady, liff, sendMessages, userId, detectDevice } = useLiff(
    liffId,
    noteId
  );

  const device = detectDevice();

  const fetchNoteData = async (userId, noteId) => {
    if (!userId || !noteId) return;
    const res = await dispatch(fchNote(userId, noteId));
    setStatus(res.status);
    setRemainingMins(res.remainingMins);
    if (res.status !== 'DONE' && res.status !== 'FAILED')
      showSuccessAlert('轉錄中，請稍後。');
    return;
  };

  const fetchNoteTask = async (userId, taskId) => {
    if (!userId || !taskId) return;
    const res = await dispatch(fchNoteTask(userId, taskId));
    setStatus(res.status);
    setNoteId(res.note_id);
    setRemainingMins(res.remainingMins);
    if (res.status !== 'DONE' && res.status !== 'FAILED')
      showSuccessAlert('轉錄中，請稍後。');
    return;
  };

  const closeWindow = async () => {
    let msg = `/q ${noteId}`;
    if (taskId) msg = `/t ${taskId}`;
    await sendMessages(msg);
    liff.closeWindow();
  };

  useEffect(() => {
    if (taskId) {
      fetchNoteTask(userId, taskId);
    } else {
      fetchNoteData(userId, noteId);
    }
  }, [noteId, userId, taskId]);

  useEffect(() => {
    if (status === 'DONE' && device === 'PC') {
      const timeout = setTimeout(() => {
        window.location.href = `/note/edit/${noteId}`;
      }, 2000);

      return () => clearTimeout(timeout);
    }

    if (status === 'DONE' || status === 'FAILED') {
      const timeout = setTimeout(() => {
        closeWindow();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [status]);

  if (!isReady) return <Loading />;

  return (
    <Box
      sx={{
        px: 2,
        width: '100%',
        height: '100%',
        py: 2,
      }}>
      <Box sx={{ position: 'relative', top: '10%' }}>
        <Typography
          variant='h1'
          textAlign='center'
          mb={5}
          sx={{ color: statusSchedule[status].color }}>
          {statusSchedule[status].text}
        </Typography>
        <Typography
          variant='h6'
          textAlign='center'
          fontSize={12}
          color={status === 'DONE' ? 'primary' : '#999'}>
          {status !== 'DONE' && status !== 'FAILED' && remainingMins < 0
            ? '重新預估處理時間中，請稍待一下。'
            : `轉錄中，剩餘 ${remainingMins} 分鐘。`}
          {status === 'DONE' &&
            `已完成，${
              device === 'PC'
                ? '即將跳轉至編輯頁面。'
                : '手機版視窗將在三秒後關閉。'
            }`}
          {status === 'FAILED' && '轉錄失敗，三秒後關閉視窗。'}
        </Typography>
        <Typography
          variant='h6'
          textAlign='center'
          fontSize={12}
          color='#999'
          mb={2}>
          若轉錄超過五分鐘，系統會透過Line主動通知。
        </Typography>
        <LinearProgress
          variant='determinate'
          value={statusSchedule[status].value}
          sx={{ mb: 1 }}
        />
        <Typography variant='h4' textAlign='center' mb={6}>
          {statusSchedule[status].value}%
        </Typography>
        {status !== 'DONE' && status !== 'FAILED' && (
          <Button
            variant='contained'
            onClick={() =>
              taskId
                ? fetchNoteTask(userId, taskId)
                : fetchNoteData(userId, noteId)
            }
            sx={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
            重新整理
          </Button>
        )}
        {(status === 'DONE' || status === 'FAILED') && (
          <Button
            variant='contained'
            onClick={closeWindow}
            sx={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
            關閉視窗
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default LiffStatus;
