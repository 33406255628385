import moment from 'moment';

import { Grid, Input } from '@mui/material';

import { noteTypeDict } from 'app/utils/constants/noteType';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TitleArea = ({ note, handleChange }) => {
  const { t } = useTranslation();

  const date = moment.unix(note?.created_at).format('YYYY-MM-DD');
  const titleText = note?.content.substring(0, 20).replace(/\n/g, '') + '...';
  const type = t(noteTypeDict[note?.source.type]);
  let title = `${type} - ${date} - ${titleText}`;
  if (note.title) title = note.title;
  return (
    <Grid item md={12} xs={12} sx={{ pt: 2 }}>
      <Input
        fullWidth
        disableUnderline
        value={title}
        onChange={handleChange}
        multiline
        sx={{
          fontSize: 28,
          padding: '4px 16px 5px',
          fontWeight: 700,
          lineHeight: '32px',
        }}
      />
    </Grid>
  );
};

export default TitleArea;
