import React from 'react';

import SubscriptionPlanPage from 'app/pages/Subscription';

const subscriptionRoutes = [
  {
    path: '/subscription',
    element: <SubscriptionPlanPage />,
  },
];

export default subscriptionRoutes;
