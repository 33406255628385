export const SEARCH_NOTES = 'SEARCH/SEARCH_NOTES';
export const SEARCH_NOTES_DONE = 'SEARCH/SEARCH_NOTES_DONE';

export const FCH_NOTELIST = 'SEARCH/FCH_NOTELIST';
export const FCH_NOTELIST_DONE = 'SEARCH/FCH_NOTELIST_DONE';

export const FCH_STATUSLIST = 'SEARCH/FCH_STATUSLIST';
export const FCH_STATUSLIST_DONE = 'SEARCH/FCH_STATUSLIST_DONE';

export const FCH_NOTE = 'SEARCH/FCH_NOTE';
export const FCH_NOTE_DONE = 'SEARCH/FCH_NOTE_DONE';

export const FCH_VIEW_NOTE = 'SEARCH/FCH_VIEW_NOTE';
export const FCH_VIEW_NOTE_DONE = 'SEARCH/FCH_VIEW_NOTE_DONE';

export const FCH_NOTE_TASK = 'SEARCH/FCH_NOTE_TASK';
export const FCH_NOTE_TASK_DONE = 'SEARCH/FCH_NOTE_TASK_DONE';

export const SET_KEYWORD = 'SEARCH/SET_KEYWORD';
export const SET_SEARCH_DATE_CONDITION = 'SEARCH/SET_SEARCH_DATE_CONDITION';
export const DEL_SEARCH_DATE_CONDITION = 'SEARCH/DEL_SEARCH_DATE_CONDITION';

export const REFRESH_NOTE_STATUS = 'SEARCH/REFRESH_NOTE_STATUS';
export const REFRESH_NOTE_STATUS_DONE = 'SEARCH/REFRESH_NOTE_STATUS_DONE';

