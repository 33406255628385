import React from 'react';
import NoteItemList from './NoteItemList';
import Stack from '@mui/material/Stack';
import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { useDispatch, useSelector } from 'react-redux';
import GridViewIcon from '@mui/icons-material/GridView';
import { selNotes, delNote, cngListView } from 'app/redux/actions/note';
import { fchNoteList } from 'app/redux/actions/search';
import ConfirmModal from '../ConfirmModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { searchNotes } from 'app/redux/actions/search';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAlert from 'app/hooks/useAlert';
import ExtraMenu from './ExtraMenu';

const noteListLength = 20;
const searchPageSize = 10;

const NoteList = ({ noteList, selectedNotes }) => {
  const { showSuccessAlert } = useAlert();
  const [delModalOpen, setDelModalOpen] = React.useState(false);
  const [noteId, setNoteId] = React.useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const uid = useSelector(({ app }) => app.user.id);
  const total_count = useSelector(({ search }) => search.get('total_count'));
  const isSearching = useSelector(({ search }) =>
    search.getIn(['config', 'isSearching'])
  );
  const keyword = useSelector(({ search }) =>
    search.getIn(['config', 'keyword'])
  );
  const listView = useSelector(({ note }) =>
    note.getIn(['config', 'listView'])
  );
  const labelList = useSelector(({ search }) =>
    search.getIn(['results', 'labels'])
  );

  const startDate = useSelector(({ search }) =>
    search.getIn(['config', 'start_date'])
  );
  const endDate = useSelector(({ search }) =>
    search.getIn(['config', 'end_date'])
  );

  const handleChangeGridView = () => {
    dispatch(cngListView(!listView));
  };

  const handleSelect = (id, e) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(selNotes(id, e.target.checked));
  };

  const handleDelete = () => {
    dispatch(delNote(uid, noteId));
    handleDelModalClose();
    return showSuccessAlert('已成功刪除文章');
  };

  const handleDelModalOpen = (nid, e) => {
    e.stopPropagation();
    setNoteId(nid);
    setDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setDelModalOpen(false);
    setNoteId(null);
  };

  const handleFchMore = () => {
    if (isSearching) {
      return dispatch(
        searchNotes(
          uid,
          keyword,
          Math.floor(noteList.length / searchPageSize) + 1,
          startDate,
          endDate
        )
      );
    }
    return dispatch(
      fchNoteList(
        uid,
        Math.floor(noteList.length / noteListLength) + 1,
        startDate,
        endDate
      )
    );
  };

  // TODO: DROPDOWN MENU

  if (!noteList) return null;

  return (
    <JumboCardQuick
      title={`${t('NOTE_LIST')}`}
      subheader={` ${selectedNotes.length} ${t('NOTE_SELECTED')}`}
      action={
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <GridViewIcon
            sx={{ mr: 1, cursor: 'pointer' }}
            component='svg'
            onClick={handleChangeGridView}
          />
          <ExtraMenu />
        </Stack>
      }
      headerSx={{
        borderBottom: 1,
        borderBottomColor: 'divider',
        px: 3,
      }}
      wrapperSx={{ p: 0, overflowY: 'scroll', height: 'calc(100vh - 280px)' }}
      sx={{ height: 'calc(100vh - 180px)' }}>
      <InfiniteScroll
        dataLength={noteList.length}
        hasMore={noteList.length < total_count}
        next={handleFchMore}
        style={{ padding: '16px 16px 32px', height: '100%' }}
        scrollableTarget='jumbo_card_content'
        loader={
          <Box
            component='div'
            sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        }>
        <NoteItemList
          dataSource={noteList}
          listView={listView}
          labelList={labelList}
          handleSelect={handleSelect}
          handleDelete={handleDelete}
          selectedNotes={selectedNotes}
          handleDelModalOpen={handleDelModalOpen}
        />
      </InfiniteScroll>

      <ConfirmModal
        isOpen={delModalOpen}
        title={t('MODAL_TITLE_WARNING')}
        content={t('MODAL_DELETE_NOTE')}
        handleClose={handleDelModalClose}
        handleSubmit={handleDelete}
        okText={t('MODAL_CONFIRM')}
        cancelText={t('MODAL_CANCEL')}
      />
    </JumboCardQuick>
  );
};
/* Todo scrollHeight prop define */
export default NoteList;
