import React from 'react';
import Login from '../../pages/Auth/Login';

const authRoutes = [
  {
    path: '/user/login',
    element: <Login />,
  },
];

export default authRoutes;
