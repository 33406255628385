import 'react-h5-audio-player/lib/styles.css';

import AudioPlayer from 'react-h5-audio-player';
import FloatController from './FloatController';
import React, { useCallback, useEffect, useRef } from 'react';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const AudioH5Player = ({
  url,
  isReady,
  isImmersive,
  formatHour,
  formatMinute,
  formatSecond,
  handleUpdateTime,
  handlePlay,
  handlePause,
  handleCheckReady,
  handleBackToTop,
}) => {
  const playerRef = useRef(null);

  const handlePlayPlayer = useCallback(() => {
    handlePlay();
    playerRef.current.audio.current.play();
  }, [handlePlay]);

  const handlePausePlayer = useCallback(() => {
    handlePause();
    playerRef.current.audio.current.pause();
  }, [handlePause]);

  const handleCheckPlay = useCallback(
    status => {
      if (status === 4) {
        handleCheckReady(true);
      }
    },
    [handleCheckReady]
  );

  const onForwardClick = () => {
    playerRef.current.audio.current.currentTime += 5;
  };

  const onBackwardClick = () => {
    playerRef.current.audio.current.currentTime -= 5;
  };

  const customIcons = {
    rewind: <Replay5Icon fontSize='large' />,
    forward: <Forward5Icon fontSize='large' />,
    play: <PlayArrowIcon sx={{ fontSize: 40 }} />,
    pause: <PauseIcon sx={{ fontSize: 40 }} />,
  };

  return (
    <>
      <AudioPlayer
        controls
        className='audio-player'
        ref={playerRef || null}
        style={{ boxShadow: 'none' }}
        src={url}
        timeFormat='hh:mm:ss'
        listenInterval={100}
        onCanPlay={e => handleCheckPlay(e.target.readyState)}
        onPlay={handlePlayPlayer}
        onPause={handlePausePlayer}
        onListen={e => handleUpdateTime(e.target.currentTime)}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        customIcons={customIcons}
      />
      {!isImmersive && (
        <FloatController
          playType='audio'
          isPlay={
            playerRef.current && playerRef.current.audio.current.paused
              ? false
              : true
          }
          formatHour={formatHour}
          formatMinute={formatMinute}
          formatSecond={formatSecond}
          handlePlay={handlePlayPlayer}
          handlePause={handlePausePlayer}
          onForwardClick={onForwardClick}
          onBackwardClick={onBackwardClick}
          handleBackToTop={handleBackToTop}
        />
      )}
    </>
  );
};

export default React.memo(AudioH5Player);
