import useJumboLayout from '@jumbo/hooks/useJumboLayout';
import Div from '@jumbo/shared/Div';
import { lineLoginSuccess } from 'app/redux/actions/auth';
import NoteList from 'app/shared/NoteList';
import { useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom/dist';

const Home = () => {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setJumboLayoutOptions } = useJumboLayout();
  const smMatch = useMediaQuery('(max-width: 959px)');

  const token = searchParam.get('token');

  const noteData = useSelector(({ note }) => note);
  const notes = useSelector(({ search }) => search.getIn(['results', 'notes']));

  useEffect(() => {
    if (token) {
      dispatch(lineLoginSuccess(token));
      navigate('/');
    }
  }, [token]);

  useEffect(() => {
    setJumboLayoutOptions({
      sidebar: { open: !smMatch },
      footer: { hide: !smMatch },
    });
  }, [smMatch]);

  return (
    <Div id='content' sx={{ px: { md: 6 }, py: 2 }}>
      <NoteList
        noteList={notes}
        selectedNotes={noteData.get('selectedNotes')}
      />
    </Div>
  );
};

export default Home;
