import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import { useDispatch, useSelector } from 'react-redux';
import {
  delSearchDateCondition,
  fchNoteList,
  searchNotes,
  setKeyword,
  setSearchDateCondition,
} from 'app/redux/actions/search';
import { debounce } from 'lodash';
import SearchFilter from 'app/shared/SearchFilter';
import DatePickerModal from 'app/shared/DatePickerModal';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { styled } from '@mui/material/styles';
import { uploadFile } from 'app/redux/actions/note';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import useAlert from 'app/hooks/useAlert';

const CommonHeader = () => {
  const smMatch = useMediaQuery('(max-width:575px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showErrorAlert, showSuccessAlert } = useAlert();

  const [open, setOpen] = useState(false);
  const [updStatus, setUpdStatus] = useState('INIT');

  const userId = useSelector(({ app }) => app.user.id);
  const keyword = useSelector(({ search }) =>
    search.getIn(['config', 'keyword'])
  );
  const searchCondition = useSelector(({ search }) =>
    search.getIn(['config', 'searchCondition'])
  );
  const startDate = useSelector(({ search }) =>
    search.getIn(['config', 'start_date'])
  );
  const endDate = useSelector(({ search }) =>
    search.getIn(['config', 'end_date'])
  );

  const notes = useSelector(({ search }) => search.getIn(['results', 'notes']));

  const handleSearch = e => {
    if (e.target.value.length >= 2 || !e.target.value.length) {
      return dispatch(searchNotes(userId, e.target.value));
    }
  };
  
  const debounceSearch = debounce(handleSearch, 2000);
  
  const handleChange = e => {
    dispatch(setKeyword(e.target.value));
    debounceSearch(e);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDateRangeSubmit = (start_date, end_date) => {
    dispatch(setSearchDateCondition(start_date, end_date));
    if (keyword === '') {
      return dispatch(fchNoteList(userId, 1, start_date, end_date));
    }
    return dispatch(searchNotes(userId, keyword, 1, start_date, end_date));
  };

  const onDelDateRange = () => {
    dispatch(
      delSearchDateCondition(userId, keyword, Math.floor(notes.length / 10) + 1)
    );
  };

  const handleFileChange = async e => {
    const file = e.target.files[0];
    setUpdStatus('UPLOADING');
    const res = await dispatch(uploadFile(file, userId));
    if (!res) {
      setUpdStatus('FAIL');
      showErrorAlert('上傳失敗，請重新上傳');
      setTimeout(() => setUpdStatus('INIT'), 3000);
      return;
    }
    setUpdStatus('SUCCESS');
    showSuccessAlert('上傳成功，即將跳轉至任務進度頁面');
    navigate('/note/status');
  };

  const UploadButton = ({ handleFileChange, status }) => {
    const statusDict = {
      INIT: {
        text: '檔案上傳',
        BtnIcon: <CloudUploadIcon />,
        color: 'info',
      },
      UPLOADING: {
        text: '上傳中',
        BtnIcon: <CircularProgress size={16} />,
        color: 'info',
      },
      SUCCESS: {
        text: '上傳成功',
        BtnIcon: <DoneIcon />,
        color: 'success',
      },
      FAIL: {
        text: '上傳失敗',
        BtnIcon: <ErrorIcon />,
        color: 'error',
      },
    };

    const VisuallyHiddenInput = styled('input')({
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: 1,
    });

    return (
      <Button
        size='small'
        variant='contained'
        color={statusDict[status].color}
        disabled={status === 'UPLOADING'}
        component='label'
        startIcon={statusDict[status].BtnIcon}>
        {statusDict[status].text}
        <VisuallyHiddenInput type='file' onChange={handleFileChange} />
      </Button>
    );
  };

  return (
    <Div sx={{ width: '100%' }}>
      {!smMatch && (
        <Stack
          flexDirection='row'
          width='100%'
          justifyContent='flex-end'
          gap={1}
          mb={2}>
          <UploadButton
            handleFileChange={handleFileChange}
            status={updStatus}
          />
          <Button
            size='small'
            variant='contained'
            color='info'
            startIcon={<AssignmentIcon />}
            onClick={() => navigate('/note/status')}>
            任務進度
          </Button>
          <Button
            size='small'
            variant='contained'
            color='info'
            startIcon={<SettingsIcon />}
            onClick={() => navigate('/setting')}>
            使用者設定
          </Button>
        </Stack>
      )}
      <Div
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fff',
          borderRadius: 2,
          boxShadow: '0 0.5rem 1.25rem rgba(115, 82, 199, 0.175);',
        }}>
        <Div
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '8px 10px',
            borderRadius: 2,
          }}>
          {smMatch && (
            <img
              src='/images/logo-main.png'
              alt='logo'
              style={{ width: '50px', height: '50px' }}
            />
          )}

          <Div
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              px: 1,
            }}>
            <SearchIcon sx={{ marginRight: 1 }} />
            <TextField
              size='small'
              sx={{ width: '100%', mr: 2, ml: 1 }}
              value={keyword}
              onChange={handleChange}
              // onKeyUp={debounceSearch}
            />
            <SearchFilter handleDatePickerOpen={handleOpen} />
          </Div>
        </Div>

        {searchCondition.includes('date_range') && (
          <Div
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pb: 1,
              px: 2,
            }}>
            <IconButton onClick={onDelDateRange}>
              <CloseIcon color='primary' sx={{ fontSize: 18 }} />
            </IconButton>
            <Typography variant='h5' component='h2' sx={{ mr: 1, mb: 0 }}>
              時間區間：
            </Typography>
            <Chip
              label={`${startDate} - ${endDate}`}
              color='primary'
              variant='outlined'
              component='button'
              onClick={handleOpen}
            />
          </Div>
        )}
        <DatePickerModal
          open={open}
          startDate={startDate}
          endDate={endDate}
          handleClose={handleClose}
          handleSubmit={(start_date, end_date) =>
            onDateRangeSubmit(start_date, end_date)
          }
        />
      </Div>
    </Div>
  );
};

export default CommonHeader;
