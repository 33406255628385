import authRoutes from './auth';
import chatgptRoutes from './chatgpt';
import homeRoutes from './home';
import noteRoutes from './note';
import subscriptionRoutes from './subscription';
import settingRoutes from './setting';
import errorRoutes from './error';
import liffRoutes from './liff';

const routes = [
  ...authRoutes,
  ...homeRoutes,
  ...noteRoutes,
  ...settingRoutes,
  ...subscriptionRoutes,
  ...chatgptRoutes,
  ...liffRoutes,
  ...errorRoutes,
];

export const withoutLayoutRoutes = [...liffRoutes];

export default routes;
