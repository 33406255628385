import { Grid, Stack, Button, Menu, MenuItem, CircularProgress } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { polishNote, summaryNote, translateNote } from 'app/redux/actions/note';
import { useNavigate } from 'react-router-dom';

const UrlArea = ({ url, uId, nId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isPolishing, setIsPolishing] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const open = Boolean(anchorEl);

  const handleSummary = async () => {
    setIsSummarizing(true);
    const res = await dispatch(summaryNote(uId, nId));
    if (res.success) {
      navigate('/note/status');
      return;
    }
    setIsSummarizing(false);
    throw new Error('Failed to get summary');
  };

  const handlePolish = async () => {
    setIsPolishing(true);
    const res = await dispatch(polishNote(uId, nId));
    if (res.success) {
      navigate('/note/status');
      return;
    }
    setIsPolishing(false);
    throw new Error('Failed to polish');
  };

  const handleOpenTranslate = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTranslate = () => {
    setAnchorEl(null);
  };

  const handleTranslate = async lang => {
    setAnchorEl(null);
    setIsTranslating(true);
    const res = await dispatch(translateNote(uId, nId, lang));
    if (res.success) {
      navigate('/note/status');
      return;
    }
    setIsTranslating(false);
    throw new Error('Failed to translate');
  };

  const handleGoToUrl = () => {
    window.open(url, '_blank');
  };

  return (
    <Grid item container md={12} xs={12} sx={{ p: '7px 16px' }}>
      <Stack
        gap={1}
        width='100%'
        flexDirection='row'
        alignContent='center'
        justifyContent='center'>
        {url && (
          <Button variant='contained' size='small' onClick={handleGoToUrl}>
            {t('NOTE_URL')}
          </Button>
        )}
        <Button
          disabled={isSummarizing}
          startIcon={isSummarizing && <CircularProgress size={12} />}
          variant='contained'
          size='small'
          onClick={handleSummary}>
          {t('SUMMARY')}
        </Button>
        <Button
          disabled={isTranslating}
          startIcon={isTranslating && <CircularProgress size={12} />}
          variant='contained'
          size='small'
          onClick={handleOpenTranslate}>
          {t('TRANSLATE')}
        </Button>
        <Button
          disabled={isPolishing}
          startIcon={isPolishing && <CircularProgress size={12} />}
          variant='contained'
          size='small'
          onClick={handlePolish}>
          {t('POLISH')}
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleCloseTranslate}>
          <MenuItem onClick={() => handleTranslate('en')}>英文</MenuItem>
          <MenuItem onClick={() => handleTranslate('zh')}>中文</MenuItem>
          <MenuItem onClick={() => handleTranslate('js')}>日文</MenuItem>
          <MenuItem onClick={() => handleTranslate('ko')}>韓文</MenuItem>
        </Menu>
      </Stack>
    </Grid>
  );
};

export default memo(UrlArea);
