import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const [auth, setAuth] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('AIMochi');

  useEffect(() => {
    if (!token) {
      return setAuth(false);
    }
    return setAuth(true);
  }, [token, navigate]);

  return auth;
};

export default useAuth;
