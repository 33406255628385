import React from 'react';

import Error404 from 'app/pages/Error/error404';
import Error403 from 'app/pages/Error/error403';
import { Navigate } from 'react-router-dom';

const errorRoutes = [
  {
    path: '/404',
    element: <Error404 />,
  },
  {
    path: '/403',
    element: <Error403 />,
  },
  {
    path: '*',
    element: <Navigate to='/404' replace />,
  },
];

export default errorRoutes;
