import React from 'react';
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar';
import { IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { SIDEBAR_STYLES } from '@jumbo/utils/constants';
import CommonHeader from './common';
import NotePageHeader from './note';
import { useSelector } from 'react-redux';
import ViewHeader from './view';

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const isEditPage = window.location.pathname.includes('/edit');
  const isChatPage = window.location.pathname.includes('/askChatGpt');
  const isSettingPage = window.location.pathname.includes('/setting');
  const isSubscriptionPage = window.location.pathname.includes('/subscription');
  const isViewPage = window.location.pathname.includes('/view');
  const is404page = window.location.pathname.includes('/404');
  const is403page = window.location.pathname.includes('/403');
  const isRecordPage = window.location.pathname.includes('/record');
  const isNoteStatus = window.location.pathname.includes('/note/status');

  const smMatch = useMediaQuery('(max-width:575px)');

  const searchCondition = useSelector(({ search }) =>
    search.getIn(['config', 'searchCondition'])
  );

  // if (!isAuth && !isViewPage) return null;

  const HeaderType = () => {
    switch (true) {
      case isEditPage:
      case isRecordPage:
        return <NotePageHeader />;
      case isChatPage:
        return <CommonHeader />;
      case isSettingPage:
      case isNoteStatus:
      case isSubscriptionPage:
        return <NotePageHeader />;
      case isViewPage:
      case is404page:
      case is403page:
        return <ViewHeader />;
      default:
        return <CommonHeader />;
    }
  };

  return (
    <React.Fragment>
      {!smMatch &&
        !isViewPage &&
        !is404page &&
        !is403page &&
        (sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
          (sidebarOptions.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
            !sidebarOptions?.open)) && (
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            sx={{
              ml:
                sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                  ? -2
                  : 0,
              mr: 3,
              alignSelf: 'center',
            }}
            onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}>
            {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        )}

      <HeaderType />
    </React.Fragment>
  );
};

export default Header;
