import Div from '@jumbo/shared/Div';
import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ConfirmModal from '../ConfirmModal';
import { clearNotes, delBatchNotes, mergeNote } from 'app/redux/actions/note';
import useAlert from 'app/hooks/useAlert';

const ExtraMenu = () => {
  const { showSuccessAlert } = useAlert();

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mergeOpen, setMergeOpen] = useState(false);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const noteData = useSelector(({ note }) => note);
  const userId = useSelector(({ app }) => app.user.id);

  const open = Boolean(anchorEl);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelMergeOpen = () => {
    setMergeOpen(true);
    handleClose();
  };

  const handleMergeClose = () => {
    setMergeOpen(false);
  };

  const handleDelModalClose = () => {
    setDelModalOpen(false);
  };

  const handleDelModalOpen = e => {
    e.stopPropagation();
    setDelModalOpen(true);
  };

  const handleMergeNote = async () => {
    const ids = noteData.get('selectedNotes');
    setIsLoading(true);
    await dispatch(mergeNote(userId, ids));
    setIsLoading(false);
    handleMergeClose();
    handleClose();
    showSuccessAlert(`已成功合併 ${ids.length} 筆筆記`);
    dispatch(clearNotes());
  };

  const handleBatchDelNote = async () => {
    const ids = noteData.get('selectedNotes');
    setIsLoading(true);
    await dispatch(delBatchNotes(userId, ids));
    setIsLoading(false);
    handleDelModalClose();
    handleClose();
    showSuccessAlert(`已成功刪除 ${ids.length} 筆筆記`);
    dispatch(clearNotes());
  };

  const selNotes = noteData.get('selectedNotes');

  return (
    <Div>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem disabled={selNotes.length === 0} onClick={handelMergeOpen}>
          合併筆記
        </MenuItem>
        <MenuItem disabled={selNotes.length === 0} onClick={handleDelModalOpen}>
          批次刪除筆記
        </MenuItem>
      </Menu>

      <ConfirmModal
        isOpen={mergeOpen}
        isLoading={isLoading}
        handleSubmit={handleMergeNote}
        handleClose={handleMergeClose}
        title='資訊'>
        <Typography
          id='transition-modal-title'
          variant='h4'
          component='h2'
          sx={{ mb: 4, textAlign: 'center' }}>
          請問要合併這些筆記嗎？
        </Typography>
      </ConfirmModal>

      <ConfirmModal
        isOpen={delModalOpen}
        isLoading={isLoading}
        handleSubmit={handleBatchDelNote}
        handleClose={handleDelModalClose}
        title='資訊'>
        <Typography
          id='transition-modal-title'
          variant='h4'
          component='h2'
          sx={{ mb: 4, textAlign: 'center' }}>
          請問要刪除總共 {selNotes.length} 筆記嗎？
        </Typography>
      </ConfirmModal>
    </Div>
  );
};

export default ExtraMenu;
