export const noteTypeDict = {
  text: 'TEXT_NOTE',
  image: 'IMAGE_NOTE',
  audio: 'AUDIO_NOTE',
  url: 'LINK_NOTE',
  file: 'FILE_NOTE',
  summary_text: 'SUMMARY_NOTE',
  polish_text: 'POLISH_NOTE',
  translate_text: 'TRANSLATE_NOTE',
};
