import React, { useEffect } from 'react';
import JumboLayout from '@jumbo/components/JumboLayout';
import Header from '../shared/headers/Header';
import Sidebar from '../shared/sidebars/Sidebar';
import Footer from '../shared/footers/Footer';
import useJumboLayout from '@jumbo/hooks/useJumboLayout';
import layoutConfig from './layoutConfig';
import {
  useJumboHeaderTheme,
  useJumboLayoutSidebar,
  useJumboTheme,
} from '@jumbo/hooks';
import { SIDEBAR_STYLES } from '@jumbo/utils/constants';
import { headerTheme as theme4 } from '../../themes/header/theme4';
import { headerTheme as defaultTheme } from '../../themes/header/default';
import { useApp } from '../../hooks';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import detectDeviceType from 'app/utils/detectDeviceType';

const VerticalDefault = ({ children }) => {
  const { setJumboLayoutOptions } = useJumboLayout();
  const { headerTheme, setHeaderTheme } = useJumboHeaderTheme();
  const { theme } = useJumboTheme();
  const appBarBgColor =
    headerTheme.components?.MuiAppBar?.styleOverrides?.root?.background;
  const { sidebarOptions } = useJumboLayoutSidebar();
  const appData = useApp();
  const searchCondition = useSelector(({ search }) =>
    search.getIn(['config', 'searchCondition'])
  );

  const isMdMatch = useMediaQuery(theme.breakpoints.up('md'));

  const [baseHeight, setBaseHeight] = React.useState(98);

  const isLiffPage = document.location.pathname.includes('/liff');
  const isLoginPage = document.location.pathname.includes('/user/login');
  const isNotePage = document.location.pathname.includes('/note/');
  const isViewPage = document.location.pathname.includes('/view/');
  const isErrorPage = document.location.pathname.includes('/40');

  const mdMatch = useMediaQuery(theme.breakpoints.up('md'));

  React.useEffect(() => {
    switch (true) {
      case isLiffPage || isLoginPage || isErrorPage:
        layoutConfig.sidebar.open = false;
        layoutConfig.footer.hide = true;
        layoutConfig.header.hide = true;
        break;
      case isViewPage || isNotePage:
        layoutConfig.sidebar.open = false;
        layoutConfig.footer.hide = true;
        break;
      default:
        layoutConfig.sidebar.open = mdMatch;
        layoutConfig.footer.hide = mdMatch;
        layoutConfig.header.hide = false;
        break;
    }

    setJumboLayoutOptions(layoutConfig);
  }, [isLiffPage, isLoginPage, isErrorPage, isViewPage, mdMatch]);

  useEffect(() => {
    if (!isMdMatch) {
      setBaseHeight(98);
    } else {
      setBaseHeight(134);
    }
  }, [isMdMatch]);

  React.useEffect(() => {
    if (
      appBarBgColor === '#F5F7FA' &&
      sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
    ) {
      setHeaderTheme({ ...theme, ...theme4 });
      appData.setAppState({ prevHeaderBgColor: '#F5F7FA' });
    } else {
      if (
        appData.prevHeaderBgColor &&
        appData.prevHeaderBgColor === '#F5F7FA'
      ) {
        setHeaderTheme({ ...theme, ...defaultTheme });
      }
    }
  }, [sidebarOptions.style]);

  return (
    <JumboLayout
      header={<Header />}
      sidebar={<Sidebar />}
      footer={<Footer />}
      headerSx={{
        height: searchCondition.length > 0 ? baseHeight + 36 : baseHeight, //36px
        // height: searchCondition.length > 0 ? 134 : 98, //36px
        background: isNotePage
          ? 'linear-gradient(to right, #3E3D92 0%,#3E3D92 86%,#59337C 100%)'
          : null,
      }}>
      {children}
    </JumboLayout>
  );
};

export default VerticalDefault;
