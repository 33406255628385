import Div from '@jumbo/shared/Div';
import {
  Modal,
  Backdrop,
  Fade,
  Divider,
  Typography,
  Box,
  Stack,
  Button,
  FormControl,
  FormHelperText,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';

const DatePickerModal = ({
  open,
  endDate,
  startDate,
  handleClose,
  handleSubmit,
}) => {
  const errorMsg = {
    0: '',
    1: '結束日期不可大於開始日期',
    2: '結束日期不可大於今天',
  };

  const [start_date, setStartDate] = useState(moment().subtract(7, 'days'));
  const [end_date, setEndDate] = useState(moment());
  const [error, setError] = useState(0);
  const smMatch = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleStartDateChange = date => {
    if (moment(date).diff(moment(end_date)) > 0) setError(1);
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    if (moment(date).diff(moment()) > 0) setError(2);
    setEndDate(date);
  };

  const onConfirm = () => {
    if (error > 0) return;
    handleSubmit(
      moment(start_date).format('YYYY-MM-DD'),
      moment(end_date).format('YYYY-MM-DD')
    );
    handleClose();
  };

  const onCancel = () => {
    setError(0);
    handleClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <Div
          sx={{
            width: smMatch ? '84%' : '460px',
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}>
          <Typography
            id='transition-modal-title'
            variant='h1'
            sx={{ textAlign: 'center', mb: 3, fontWeight: 700 }}>
            選擇日期區間
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Div sx={{ display: 'flex', flexDirection: 'column' }}>
            <Div sx={{ display: 'flex', flexDirection: 'row' }}>
              <DatePicker
                defaultValue={startDate ? moment(startDate) : start_date}
                onChange={handleStartDateChange}
                slotProps={{
                  textField: {
                    error: error > 0,
                  },
                }}
              />
              <Typography
                variant='h4'
                component='h2'
                sx={{ mx: 2, alignSelf: 'center' }}>
                -
              </Typography>
              <DatePicker
                defaultValue={endDate ? moment(endDate) : end_date}
                onChange={handleEndDateChange}
                slotProps={{
                  textField: {
                    error: error > 0,
                  },
                }}
              />
            </Div>
            <FormControl sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
              <FormHelperText
                error={error > 0}
                sx={{ fontSize: 14, m: 0, mt: 1 }}>
                {errorMsg[error]}
              </FormHelperText>
            </FormControl>
          </Div>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack flexDirection='row' gap={4}>
              <Button variant='contained' onClick={onConfirm}>
                確認
              </Button>

              <Button variant='outlined' onClick={onCancel}>
                取消
              </Button>
            </Stack>
          </Box>
        </Div>
      </Fade>
    </Modal>
  );
};

export default DatePickerModal;
