import Immutable from 'immutable';
import { UPDATE_NOTE_LABEL_DONE } from '../types/note';
import { FCH_NOTE_DONE } from '../types/search';

const INIT_STATE = Immutable.OrderedMap({
  list: [],
});

const labelReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FCH_NOTE_DONE:
      return _.set('list', payload.note.labels);
    case UPDATE_NOTE_LABEL_DONE:
      return _.set('list', payload.labels);
    default:
      return _;
  }
};

export default labelReducer;
