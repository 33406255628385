import { useState } from 'react';
import { Box, Chip } from '@mui/material';

const ChipStack = ({ dataSource, size = 5 }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const dataLength = dataSource.length;
  if (dataLength > size && !open) {
    return (
      <Box component="div">
        {dataSource.slice(0, size).map(data => (
          <Chip
            key={data.id}
            color='primary'
            label={data.name}
            size='small'
            sx={{ mr: 0.5 }}
          />
        ))}
        <Chip
          color='primary'
          label={`+${dataLength - size}`}
          size='small'
          sx={{ mr: 0.5 }}
          component='div'
          onClick={handleOpen}
        />
      </Box>
    );
  }

  return (
    <Box component="div">
      {dataSource.map(data => (
        <Chip
          key={data.id}
          color='primary'
          label={data.name}
          size='small'
          sx={{ mr: 0.5 }}
        />
      ))}
    </Box>
  );
};

export default ChipStack;
