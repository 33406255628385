import baseAxios from 'axios';

const axios = baseAxios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getRequest = endPoint => {
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
      'Cache-Control': 'no-cache',
    },
  }

  try {
    const res = axios.get(endPoint, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postRequest = (endPoint, params, ) => {
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
  }

  if (endPoint.includes('files')) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  try {
    const res = axios.post(endPoint, params, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const patchRequest = (endPoint, params) => {

  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
    },
  }

  try {
    const res = axios.patch(endPoint, params, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteRequest = endPoint => {

  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
    },
  }
  try {
    const res = axios.delete(endPoint, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};
