import Div from '@jumbo/shared/Div';
import {
  Button,
  Divider,
  Grid,
  Link,
  MenuItem,
  Select,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@mui/material';
import {
  sendRedeemCode,
  updContentLang,
  updLang,
  userLogout,
} from 'app/redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { langDict } from 'app/config/i18n/dict';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  cancelSubscription,
  getSubscriptionInfo,
} from 'app/redux/actions/subscription';
import { useEffect, useMemo, useState } from 'react';
import ConfirmModal from 'app/shared/ConfirmModal';
import moment from 'moment';

import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import useAlert from 'app/hooks/useAlert';

const planDict = {
  free: '免費',
  basic: '基本',
  premium: '高級',
};

const SettingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redeemCode, setRedeemCode] = useState('');

  const handleLogout = () => {
    dispatch(userLogout());
  };

  const user = useSelector(({ app }) => app.user);
  const subscription = useSelector(({ subscription }) =>
    subscription.get('record')
  );

  const typoStyle = {
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
  };

  const fchOrderPlan = () => {
    try {
      dispatch(getSubscriptionInfo(user.id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelModelOpen = () => {
    setOpen(true);
  };

  const handleCancelModelClose = () => {
    setOpen(false);
  };

  const handleCancelSubscription = () => {
    dispatch(cancelSubscription(user.id));
    setOpen(false);
  };

  const handleRedeemCode = async () => {
    const success = await dispatch(sendRedeemCode(user.id, redeemCode.trim()));
    if (!success) {
      return showErrorAlert(t('REDEEM_CODE_FAIL'));
    }
    setRedeemCode('');
    return showSuccessAlert(t('REDEEM_CODE_SUCCESS'));
  };

  // useEffect(() => {
  //   if (user.id) {
  //     setIsLoading(true);
  //     fchOrderPlan();
  //     setIsLoading(false);
  //   }
  // }, [user.id]);

  const subMemo = useMemo(() => subscription, [subscription]);

  if (Object.keys(user).length === 0) return null;

  return (
    <Div sx={{ padding: '16px' }}>
      <Divider textAlign='center' sx={{ fontSize: 20, mb: 2 }}>
        {t('USER_SETTING')}
      </Divider>
      <Grid container gap={6} sx={{ mb: 6 }}>
        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant='subtitle2' fontSize={18}>
              {t('INTERFACE_LANG')}
            </Typography>
          </Grid>
          <Grid item xs={4} md={1} sx={typoStyle}>
            <Typography variant='subtitle2' color='#ccc' fontSize={18}>
              {langDict[user.config.lang]}
            </Typography>
          </Grid>
          <Grid item xs={3} md={9}>
            <Select
              value={user.config.lang}
              onChange={e => dispatch(updLang(e.target.value))}>
              <MenuItem value='zh'>中文</MenuItem>
              <MenuItem value='en'>English</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant='subtitle2' fontSize={18}>
              {t('CONTENT_LANG')}
            </Typography>
          </Grid>
          <Grid item xs={4} md={1} sx={typoStyle}>
            <Typography variant='subtitle2' color='#ccc' fontSize={18}>
              {langDict[user.config.content_lang]}
            </Typography>
          </Grid>
          <Grid item xs={3} md={9}>
            <Select
              disabled
              value={user.config.content_lang}
              onChange={e => dispatch(updContentLang(e.target.value))}>
              <MenuItem value='zh'>中文</MenuItem>
              <MenuItem value='en'>English</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant='subtitle2' fontSize={18}>
              {t('MAGIC_POINT')}
            </Typography>
          </Grid>
          <Grid item xs={7} md={1} sx={typoStyle}>
            <Typography
              variant='subtitle2'
              color={
                user.point.temporary + user.point.permanent > 50
                  ? 'green'
                  : 'red'
              }
              fontSize={18}>
              {user.point.temporary + user.point.permanent}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant='subtitle2' fontSize={18}>
              {t('REF_CODE')}
            </Typography>
          </Grid>
          <Grid item xs={7} md={1} sx={typoStyle}>
            <Typography variant='subtitle2' color='green' fontSize={18}>
              {user.config.reference_code}
            </Typography>
          </Grid>
        </Grid>

        <Divider textAlign='center' sx={{ fontSize: 20, width: '100%' }}>
          {t('PLAN_CONTENT')}
        </Divider>

        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant='subtitle2' fontSize={18}>
              {t('PLAN')}
            </Typography>
          </Grid>
          <Grid item xs={4} md={1} sx={typoStyle}>
            <Typography variant='subtitle2' fontSize={18} color='green'>
              {user.plan ? planDict[user.plan] : t('PLAN_FREE')}
            </Typography>
          </Grid>
          <Grid item xs={3} md={9}>
            <Button
              variant='contained'
              sx={{ fontSize: { xs: 12, md: 14 } }}
              onClick={() => navigate('/subscription')}>
              {t('PLAN_UPGRADE')}
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={12}>
          <Grid item xs={5} md={2} sx={typoStyle}>
            <Typography variant='subtitle2' fontSize={18}>
              {t('REDEEM_CODE')}
            </Typography>
          </Grid>
          <Grid item xs={4} md={1} sx={typoStyle}>
            <TextField
              sx={{ width: '80%' }}
              size='small'
              variant='outlined'
              onChange={e => setRedeemCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={3} md={9}>
            <Button
              variant='contained'
              sx={{ fontSize: { xs: 12, md: 14 } }}
              onClick={handleRedeemCode}>
              {t('REDEEM_CODE_BTN')}
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={6}>
          <Grid item xs={6} md={4} sx={{ ...typoStyle }}>
            <Typography variant='subtitle2' fontSize={18}>
              {t('SUBSCRIPTION_RECORD')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ display: 'flex', alignItems: 'center' }}>
            <Link
              variant='text'
              sx={{
                fontSize: 12,
                cursor: 'default',
              }}
              component='p'
              onClick={handleCancelModelOpen}>
              {t('CANCEL_SUBSCRIPTION')}
            </Link>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mt: 4 }}>
            <TableContainer component='div' sx={{ border: '1px solid #ccc' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('PLAN')}</TableCell>
                    <TableCell>{t('SUBSCRIPTION_TIME')}</TableCell>
                    <TableCell>{t('EXPIRATION_TIME')}</TableCell>
                  </TableRow>
                </TableHead>
                {subMemo.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3}>{t('NO_RECORD')}</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {subMemo.length > 0 && (
                  <TableBody>
                    {subMemo.splice(0, 5).map((sub, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{planDict[sub.plan]}</TableCell>
                        <TableCell>
                          {moment
                            .unix(sub.created_at)
                            .format('YYYY-MM-DD HH:mm:ss')}
                        </TableCell>
                        <TableCell>
                          {moment
                            .unix(sub.created_at)
                            .add(1, 'years')
                            .format('YYYY-MM-DD HH:mm:ss')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ borderWidth: 0.2, width: '100%' }} />

      <Button
        onClick={handleLogout}
        variant='contained'
        sx={{
          mt: 6,
          mb: 14,
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
          width: { xs: '100%', md: '10%' },
        }}>
        {t('LOGOUT')}
      </Button>

      <ConfirmModal
        isOpen={open}
        onClose={handleCancelModelClose}
        title='警告'
        content='確認取消訂閱？'
        handleClose={handleCancelModelClose}
        handleConfirm={handleCancelSubscription}
      />
    </Div>
  );
};

export default SettingPage;
