import Div from '@jumbo/shared/Div';
import {
  Box,
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  Stack,
  TextField,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
} from '@mui/material';
import {
  getSubscriptionInfo,
  subscriptionPlan,
} from 'app/redux/actions/subscription';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

const SubscriptionPlanPage = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ app }) => app.user);

  const PlanButton = styled(ToggleButton)({
    borderRadius: '4px !important',
    border: '1px solid #ccc !important',
    width: '100%',

    '&.Mui-selected': {
      border: '1px solid #7352C7 !important',
      backgroundColor: '#F9F7FF !important',
    },
  });

  const validationSchema = yup.object({
    plan: yup.string().required('請選擇方案'),
    company_name: yup.string().when('type', {
      is: val => val === 'company',
      then: schema => schema.required('請輸入公司名稱'),
    }),
    company_id: yup.string().when('type', {
      is: val => val === 'company',
      then: schema =>
        schema.max(8, '統一編號最多8碼').required('請輸入統一編號'),
    }),
    type: yup.string().required('請選擇發票類型'),
    email: yup.string().email('請輸入正確email格式').required('請輸入Email'),
    name: yup.string().required('請輸入姓名'),
    phone: yup.string().required('請輸入電話'),
    address: yup.string().required('請輸入地址'),
  });

  const initialValues = {
    plan: '',
    company_name: '',
    company_id: '',
    type: 'personal',
    email: user.email,
    name: '',
    phone: '',
    address: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: async values => {
      const receipt = {
        type: values.type,
        name: values.name,
        phone: values.phone,
        address: values.address,
      };
      if (values.type === 'company') {
        receipt.company_name = values.company_name;
        receipt.company_id = values.company_id;
      }
      const formData = {
        email: values.email,
        plan: values.plan,
        receipt,
      };

      await dispatch(subscriptionPlan(formData, user.id));
    },
  });

  // const fchOrderPlan = () => {
  //   try {
  //     dispatch(getSubscriptionInfo(user.id));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (user.id) {
      // fchOrderPlan();
      formik.setFieldValue('plan', user.plan);
    }
  }, [user.id]);

  return (
    <Div sx={{ padding: 2, pb: 14 }}>
      <Divider textAlign='left' sx={{ fontSize: 20, mb: 4 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box
            component='div'
            color='primary'
            sx={{
              borderRadius: 99,
              backgroundColor: '#7352C7',
              width: '30px',
              color: '#fff',
            }}>
            1
          </Box>
          <Typography variant='h5' sx={{ m: 0, ml: 1 }}>
            選擇方案
          </Typography>
        </Box>
      </Divider>
      <Box sx={{ mb: 4 }}>
        <ToggleButtonGroup
          id='plan'
          exclusive
          value={formik.values.plan}
          onChange={(e, value) => formik.setFieldValue('plan', value)}
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2,
            mb: 2,
          }}>
          <PlanButton disableRipple value=''>
            <Stack>
              <Typography variant='h4'>免費版本</Typography>
              <Typography variant='h5'>
                <span style={{ color: '#7352C7' }}>NT$ 0</span>/月
              </Typography>
              <Typography variant='body1'>
                AIMochi的免費版本提供了基本的筆記和翻譯功能，讓您可以輕鬆記錄和管理資訊。每月1,000點的使用額度足夠應對一般需求，並且您可以存取最近一年的資料。適合輕度使用者。
              </Typography>
            </Stack>
          </PlanButton>
          <PlanButton disableRipple value='basic'>
            <Stack>
              <Typography variant='h4'>基本版本</Typography>
              <Typography variant='h5'>
                <span style={{ color: '#7352C7' }}>NT$ 299</span>/月
              </Typography>
              <Typography variant='body1'>
                基本版本提供每月5,000點，讓您更廣泛地使用轉錄、翻譯和摘要功能。此外，還可以購買額外的點數，自訂提示詞，滿足更多特定需求。儲存期限不受限制，讓您可以長期保存資料。
              </Typography>
            </Stack>
          </PlanButton>
          <PlanButton disableRipple value='premium'>
            <Stack>
              <Typography variant='h4'>專業版本</Typography>
              <Typography variant='h5'>
                <span style={{ color: '#7352C7' }}>NT$ 899</span>/月
              </Typography>
              <Typography variant='body1'>
                專業版本為高需求用戶提供每月20,000點額度，為專業人士的理想選擇。還可以購買額外的點數，自訂提示詞，更好管理和擴展功能。無儲存期限限制，可以保存所有重要資料。
              </Typography>
            </Stack>
          </PlanButton>
        </ToggleButtonGroup>
        {formik.errors.plan && (
          <Typography variant='body1' sx={{ color: 'red', mb: 2 }}>
            {formik.errors.plan}
          </Typography>
        )}
        <Typography variant='body1'>
          詳情更多資訊請參考
          <Link href='https://www.aimochi.ai/#our-pricings' target='_blank'>
            連結
          </Link>
        </Typography>
      </Box>

      <Divider textAlign='left' sx={{ fontSize: 20, mb: 4 }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box
            component='div'
            color='primary'
            sx={{
              borderRadius: 99,
              backgroundColor: '#7352C7',
              width: '30px',
              color: '#fff',
            }}>
            2
          </Box>
          <Typography variant='h5' sx={{ m: 0, ml: 1 }}>
            輸入發票資訊
          </Typography>
        </Box>
      </Divider>

      <Box sx={{ mb: 4 }}>
        <FormControl>
          <RadioGroup
            row
            id='type'
            name='type'
            value={formik.values.type}
            onChange={formik.handleChange}>
            <FormControlLabel
              value='personal'
              control={<Radio />}
              label='二聯式電子發票(個人/海外)'
            />
            <FormControlLabel
              value='company'
              control={<Radio />}
              label='三聯式電子發票(公司用)'
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {formik.values.type === 'company' && (
        <Box component='div'>
          <Typography variant='h5' sx={{ m: 0, mb: 2, color: '#7352C7' }}>
            公司資訊
          </Typography>
          <Stack direction='row' spacing={2} sx={{ mb: 4 }}>
            <TextField
              id='company_name'
              name='company_name'
              onChange={formik.handleChange}
              required
              label='公司名稱'
              variant='outlined'
              value={formik.values.company_name}
              helperText={formik.errors.company_name}
              error={Boolean(formik.errors.company_name)}
            />
            <TextField
              id='company_id'
              name='company_id'
              onChange={formik.handleChange}
              required
              label='公司統一編號'
              variant='outlined'
              value={formik.values.company_id}
              helperText={formik.errors.company_id}
              error={Boolean(formik.errors.company_id)}
            />
          </Stack>
        </Box>
      )}

      <Box component='div'>
        <Typography variant='h5' sx={{ m: 0, mb: 2, color: '#7352C7' }}>
          訂閱者資訊
        </Typography>
        <Grid container sx={{ mb: 4, width: { md: '50%' } }} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id='name'
              name='name'
              onChange={formik.handleChange}
              required
              fullWidth
              label='姓名'
              variant='outlined'
              value={formik.values.name}
              helperText={formik.errors.name}
              error={Boolean(formik.errors.name)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name='phone'
              onChange={formik.handleChange}
              required
              fullWidth
              label='電話'
              variant='outlined'
              value={formik.values.phone}
              helperText={formik.errors.phone}
              error={Boolean(formik.errors.phone)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              name='email'
              onChange={formik.handleChange}
              required
              fullWidth
              label='Email'
              variant='outlined'
              value={formik.values.email}
              helperText={formik.errors.email}
              error={Boolean(formik.errors.email)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              name='address'
              onChange={formik.handleChange}
              required
              fullWidth
              label='地址'
              variant='outlined'
              value={formik.values.address}
              helperText={formik.errors.address}
              error={Boolean(formik.errors.address)}
            />
          </Grid>
        </Grid>
      </Box>

      <Button
        disabled={formik.values.plan === ''}
        variant='contained'
        sx={{ width: { xs: '100%', md: 'initial' } }}
        onClick={formik.handleSubmit}>
        送出
      </Button>
    </Div>
  );
};

export default SubscriptionPlanPage;
