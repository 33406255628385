import * as uuid from 'uuid';

import { CHATGPT_ASK, CHATGPT_ASK_REPLY } from '../types/chat';
import { postRequest } from 'app/services';

export const askChatGpt = (uid, message) => {
  const url = `/api/v1//users/${uid}/_question`;

  const question = {
    message: message,
    character: 'user',
    id: uuid.v4(),
  };

  return async dispatch => {
    dispatch({ type: CHATGPT_ASK, payload: { question } });
    const res = await postRequest(url, { question: message })
    dispatch(chatGptReply(res.data.answer));
  };
};

export const chatGptReply = message => {
  const reply = {
    message: message,
    character: 'chatgpt',
    id: uuid.v4(),
  };

  return async dispatch => {
    dispatch({ type: CHATGPT_ASK_REPLY, payload: { reply } });
  };
};
