import liff from '@line/liff';
import { postRequest } from 'app/services';
import { useEffect, useState } from 'react';

const useLiff = (id, noteId) => {
  const [isReady, setIsReady] = useState(false);
  const [userId, setUserId] = useState(null);

  const sendMessages = async text => {
    try {
      await liff.ready; // 確保 init 執行完畢
      if (!liff.isInClient()) return;
      await liff.sendMessages([
        {
          type: 'text',
          text,
        },
      ]);
    } catch (err) {
      // 發生錯誤
      console.log(err.code, err.message);
      alert(err.message);
    }
  };

  const liffInit = async () => {
    await liff.init({ liffId: id });

    try {
      let redirectUri = null;
      if (noteId) redirectUri = `${window.location.origin}/liff/status?noteId=${noteId}`;
      if (!liff.isLoggedIn()) {
        liff.login({ redirectUri });
      }
      const profile = await liff.getProfile();
      const userId = profile.userId;
      await tokenExchange(userId);
      setIsReady(true);
      console.log('liff init success')
    } catch (error) {
      console.log(error);
      // alert(error.message);
    }
  };

  const tokenExchange = async id => {
    try {
      const res = await postRequest('/auth/token', { id });
      const token = res.data.payload.token;
      localStorage.setItem('AIMochi', JSON.stringify({ token }));
      const userData = JSON.parse(atob(token.split('.')[1]));
      setUserId(userData.id);
    } catch (error) {
      console.log(error);
    }
  };

  function detectDevice() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 檢測iOS裝置
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    // 檢測Android裝置
    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // 其他裝置，如PC
    return 'PC';
  }

  useEffect(() => {
    liffInit();
  }, []);

  return { liff, userId, isReady, sendMessages, detectDevice };
};

export default useLiff;
