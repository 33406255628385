import Div from '@jumbo/shared/Div';
import StatusCard from './StatusCatd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, Stack, Typography } from '@mui/material';

const StatusList = ({ dataSource, status_count, fchMore }) => {
  if (dataSource.length === 0) {
    return (
      <Div sx={{ width: '100%', py: 4 }}>
        <Stack gap={1} alignItems='center'>
          <img
            src='/images/logo-main.png'
            alt='logo'
            style={{ width: '120px', height: '120px' }}
          />
          <Typography variant='h1' textAlign='center'>
            查無任何資料
          </Typography>
        </Stack>
      </Div>
    );
  }

  return (
    <Div
      id='scroll-card-list'
      sx={{ width: '100%', height: 'calc(100vh - 270px)' }}>
      <InfiniteScroll
        dataLength={dataSource.length}
        hasMore={dataSource.length < status_count}
        next={fchMore}
        scrollableTarget='scroll-card-list'
        height={'calc(100vh - 270px)'}
        loader={
          <Div
            component='div'
            sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Div>
        }>
        <Stack gap={1.5}>
          {dataSource.map((note, index) => (
            <StatusCard
              key={note.id}
              note={note}
              lastIndex={dataSource[dataSource.length] - 1 === index}
            />
          ))}
        </Stack>
      </InfiniteScroll>
    </Div>
  );
};

export default React.memo(StatusList);
