import React from 'react';
import SettingPage from 'app/pages/Setting';

const settingRoutes = [
  {
    path: '/setting',
    element: <SettingPage />,
  },
];

export default settingRoutes;
