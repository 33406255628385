import React from 'react';
import { Editor as NovelEditor } from '@liuanboy/novel';
import './styles.css';
import { genContent } from 'app/utils/downloadDoc';

const ContentEditor = ({
  note,
  noteId,
  onDebouncedUpdate,
  onUpdate,
  debounceDuration = 750,
  disableLocalStorage = false,
  className = 'novel-editor',
  locales,
}) => {

  const content = genContent(note);

  return (
    <NovelEditor
      className={className}
      storageKey={noteId}
      defaultValue={content}
      onUpdate={onUpdate}
      onDebouncedUpdate={onDebouncedUpdate}
      debounceDuration={debounceDuration}
      disableLocalStorage={true}
      locales={locales}
    />
  );
};

export default ContentEditor;
