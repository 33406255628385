import { useMediaQuery } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';
import FloatController from './FloatController';

const VideoPlayer = props => {
  const mdMatch = useMediaQuery(theme => theme.breakpoints.down('md'));

  const {
    url,
    isImmersive,
    handleUpdateTime,
    progressInterval = 500,
    ref,
    handleCheckReady,
    handlePlay,
    handlePause,
    isPlay,
    formatHour,
    formatMinute,
    formatSecond,
    handleBackToTop,
  } = props;

  return (
    <>
      <ReactPlayer
        ref={ref}
        url={url}
        controls
        width={mdMatch ? '360px' : '640px'}
        height={mdMatch ? '202.5px' : '360px'}
        onReady={state => handleCheckReady(state.player.isReady)}
        onProgress={state => handleUpdateTime(state.playedSeconds)}
        onPlay={handlePlay}
        onPause={handlePause}
        progressInterval={progressInterval}
        playing={isPlay}
      />
      {!isImmersive && (
        <FloatController
          {...props}
          playType='video'
          isPlay={isPlay}
          formatHour={formatHour}
          formatMinute={formatMinute}
          formatSecond={formatSecond}
          handlePlay={handlePlay}
          handlePause={handlePause}
          handleBackToTop={handleBackToTop}
        />
      )}
    </>
  );
};

export default React.memo(VideoPlayer);
