import React, { useEffect } from 'react';
import {
  CardContent,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import Div from '@jumbo/shared/Div';
import { lineLogin } from 'app/redux/actions/auth';
import { useDispatch } from 'react-redux';
import useAuth from 'app/hooks/useAuth';

const Login = () => {
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const navigate = useNavigate();

  const smMatch = useMediaQuery('(max-width: 959px)');

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
  }, [isAuth]);

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: smMatch ? '100%' : '25%',
        boxShadow: smMatch ? 'none' : '0px 0px 20px rgba(0, 0, 0, 0.1)',
        maxHeight: smMatch ? null : '80vh',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        top: '50%',
        height: smMatch ? 'initial' : '100svh',
      }}>
      <Div sx={{ position: 'relative' }}>
        <img
          src='/images/logo-main.png'
          alt='logo'
          style={{
            width: 75,
            height: 75,
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
        <Div style={{ textAlign: 'center', fontSize: 32 }}>AIMochi</Div>
        <Div
          style={{
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 700,
            marginBottom: 24,
          }}>
          登入或免費註冊
        </Div>
        <Div style={{ textAlign: 'center', fontSize: 14 }}>welcome back</Div>
      </Div>
      <CardContent sx={{ width: '100%' }}>
        <LoadingButton
          fullWidth
          type='submit'
          variant='contained'
          size='large'
          sx={{
            mb: 1,
            backgroundColor: '#02C153',
            '&:hover': { backgroundColor: '#02b14b' },
          }}
          onClick={() => dispatch(lineLogin())}>
          Line Login
        </LoadingButton>
      </CardContent>
      <Div sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography color='text.secondary' align='center'>
          登入即代表您同意
        </Typography>
        <Link
          href='https://www.aimochi.ai/privacy/'
          underline='none'
          color='primary'
          align='center'
          target='_blank'
          sx={{ mx: 0.5 }}>
          隱私權條款
        </Link>
        <Typography color='text.secondary' align='center'>
          和
        </Typography>
        <Link
          href='https://www.aimochi.ai/rules/'
          underline='none'
          color='primary'
          align='center'
          target='_blank'
          sx={{ mx: 0.5 }}>
          服務條款
        </Link>
      </Div>
    </Div>
  );
};

export default Login;
