import { Box, Grid, Typography } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import { memo } from 'react';

const IDArea = ({ id }) => {
  return (
    <Grid
      component='div'
      id='section'
      item
      container
      md={12}
      xs={12}
      sx={{ p: '8px 16px' }}>
      <Grid
        item
        xs={2.6}
        md={1.2}
        lg={1.1}
        xl={0.76}
        sx={{ display: 'flex', alignItems: 'center', minWidth: 100 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mr: 0.5,
            minWidth: 50,
          }}>
          <BadgeIcon
            sx={{
              fontSize: 18,
              mr: 0.5,
            }}
          />
          <Typography variant='subtitle2' sx={{}}>
            ID
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={10}>
        <Typography
          variant='subtitle2'
          sx={{
            color: '#C5C9CB',
          }}>
          {id}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(IDArea);
