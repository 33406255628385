import Div from '@jumbo/shared/Div';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from 'app/redux/actions/note';

import useLiff from 'app/hooks/useLiff';
import Loading from 'app/shared/Loading';
import { useEffect, useState } from 'react';

const LiffUpload = () => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState('INIT');
  const [noteId, setNoteId] = useState('');

  const liffId =
    process.env.REACT_APP_LIFF_ENV === 'development'
      ? '1661300442-YzVqVvbV'
      : '2000230437-vPOBlONl';

  const statusLiffId =
    process.env.REACT_APP_LIFF_ENV === 'development'
      ? '1661300442-nrE1EDKE'
      : '2000230437-Arblxbqx';

  const { isReady, liff, sendMessages, userId, detectDevice } = useLiff(liffId);

  const device = detectDevice();

  const handleSendMessages = async id => {
    if (id) {
      await sendMessages(`/upload ${id}`);
    }
    liff.closeWindow();
  };

  const handleFileChange = async e => {
    const file = e.target.files[0];

    try {
      setStatus('UPLOADING');
      const id = await dispatch(uploadFile(file, userId));

      setStatus('SUCCESS');
      setNoteId(id);
    } catch (error) {
      console.log(error);
      setStatus('FAIL');
    }
  };

  const statusDict = {
    INIT: {
      text: '請選擇檔案',
      BtnText: '上傳檔案',
      BtnIcon: <CloudUploadIcon />,
      fn: null,
    },
    UPLOADING: {
      text: '上傳中',
      BtnText: '上傳中',
      BtnIcon: <CircularProgress size={16} />,
      fn: null,
    },
    SUCCESS: {
      text: '上傳成功',
      BtnText: '關閉視窗',
      BtnIcon: null,
      fn: () => handleSendMessages(noteId),
    },
    FAIL: {
      text: '上傳失敗',
      BtnText: '關閉視窗',
      BtnIcon: null,
      fn: () => liff.closeWindow(),
    },
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  useEffect(() => {
    if (status === 'SUCCESS' && device === 'PC') {
      return (window.location.href = `https://liff.line.me/${statusLiffId}?noteId=${noteId}`);
    }

    if (status === 'SUCCESS' && noteId) {
      const delay = 3000;
      const timeoutId = setTimeout(() => {
        handleSendMessages(noteId);
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [status, noteId, device]);

  if (!isReady) return <Loading />;

  return (
    <Div
      sx={{
        p: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '50%',
        flexDirection: 'column',
      }}>
      <Typography
        variant='h1'
        textAlign='center'
        mb={status === 'SUCCESS' ? 2 : 5}>
        {statusDict[status].text}
      </Typography>
      {status === 'SUCCESS' && (
        <Typography variant='h6' textAlign='center' mb={3}>
          視窗將在三秒後關閉。
        </Typography>
      )}
      <Button
        disabled={status === 'UPLOADING'}
        component='label'
        variant='contained'
        startIcon={statusDict[status].BtnIcon}
        onClick={statusDict[status].fn}>
        {statusDict[status].BtnText}
        {status === 'INIT' && (
          <VisuallyHiddenInput type='file' onChange={handleFileChange} />
        )}
      </Button>
    </Div>
  );
};

export default LiffUpload;
