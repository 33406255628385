import {
  Grid,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Card,
  Box,
  Checkbox,
  CardHeader,
  Chip,
  useMediaQuery,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import ChipStack from '../ChipStack';
import { useJumboTheme } from '@jumbo/hooks';

const NoteGridView = ({
  note,
  key,
  goEditPage,
  selectedNotes,
  handleSelect,
  type,
  handleDelModalOpen,
  labelList,
}) => {
  const { theme } = useJumboTheme();
  const xsMatch = useMediaQuery(theme.breakpoints.down('sm'));
  const labels = labelList.filter(label => note.labels.includes(label.id));

  return (
    <Grid item xs={6} md={4} key={key}>
      <Card component='div' sx={{ maxHeight: 320, minHeight: 260 }}>
        <CardHeader
          sx={{ p: 2, pb: 0 }}
          action={
            <Checkbox
              sx={{ p: 0 }}
              edge='start'
              checked={selectedNotes.includes(note.id)}
              onChange={e => handleSelect(note.id, e)}
            />
          }
          title={<Chip label={type} size='small' />}
        />

        <CardContent
          sx={{
            position: 'relative',
            p: 2,
            pt: 1,
            pb: 0.5,
          }}>
          <Box component='div' onClick={() => goEditPage(note.id)} mb={1}>
            <Typography mb={0.5}>
              {moment.unix(note.created_at).format('YYYY-MM-DD')}
            </Typography>
            <Typography
              sx={{
                mb: 1,
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: 600,
              }}
              variant='h4'>
              {note.title}
            </Typography>
            <Typography
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minHeight: 60,
              }}
              variant='h5'>
              {note.content}
            </Typography>
          </Box>
          <ChipStack dataSource={labels} size={xsMatch ? 2 : 5} />
        </CardContent>
        <CardActions sx={{ pl: 2, pr: 2, pb: 2, pt: 0 }}>
          <IconButton
            className={'update-task'}
            aria-label='update-task'
            onClick={e => handleDelModalOpen(note.id, e)}
            sx={{
              position: 'relative',
              zIndex: 5,
              left: '50%',
              opacity: 1,
              boxShadow: 1,
              color: 'text.primary',
              backgroundColor: 'common.white',
              transform: 'translateX(-50%)',
              mt: 1,
            }}>
            <DeleteIcon fontSize={'small'} />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default NoteGridView;
