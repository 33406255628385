import React from 'react';
import { Helmet } from 'react-helmet';

const MetaHelmet = ({ title, description, keywords, imageUrl }) => {
  const metaTitle = title ? `AIMochi - ${title}` : 'AIMochi';
  const metaDescription = trimMetaDescription(description);

  const metaKeywords = keywords
    ? keywords.join(', ')
    : 'AIMochi, 語音編輯器, 語音筆記, 語音記錄, 語音轉文字, 語音轉文字筆記, 語音轉文字記錄';

  const metaImage = imageUrl || '%PUBLIC_URL%/images/logo-main.png';

  return (
    <Helmet>
      <title>{metaTitle}</title>
      
      <meta name='title' content={metaTitle} />
      <meta name='keywords' content={metaKeywords} />
      <meta name='theme-color' content='#6200EE' />
      <meta name='description' content={metaDescription} />

      <meta property='og:description' content={metaDescription} />
      <meta property='og:title' content={metaTitle} />
      <meta property='og:image' content={metaImage} />
      <meta property='og:site_name' content='AIMochi' />

      <meta name='twitter:card' content='AIMochi' />
      <meta name='twitter:title' content={metaTitle} />
      <meta name='twitter:description' content={metaDescription} />
    </Helmet>
  );
};

export default MetaHelmet;

function trimMetaDescription(str) {
  if (!str)
    return '歡迎使用AIMochi！這是所有記憶魔法開始的地方。AIMochi是一個創新的AI助手，它可以協助您記錄各種資料，無論是文字、連結、語音、檔案還是圖片，只需透過Line傳給AIMochi，它就像是您的第二個腦，可以幫助您輕鬆記錄並隨時檢索筆記。';
  const patterns = {
    Chinese: /^[\u4E00-\u9FA5]+$/,
    Japanese: /^[\u3040-\u30FF]+$/,
    Korean: /^[\uAC00-\uD7AF]+$/,
    English: /^[A-Za-z]+$/,
  };

  if (patterns.Chinese.test(str)) {
    return str.slice(0, 70);
  } else if (patterns.Japanese.test(str)) {
    return str.slice(0, 70);
  } else if (patterns.Korean.test(str)) {
    return str.slice(0, 120);
  } else if (patterns.English.test(str)) {
    return str.slice(0, 160);
  } else {
    return str.slice(0, 70);
  }
}
