import React from 'react';
import Home from '../../pages/home';

const homeRoutes = [
  {
    path: '/',
    element: <Home />,
  },
];

export default homeRoutes;
