import { getRequest, patchRequest, postRequest } from 'app/services';

import * as __ from '../types/subscription';

export const subscriptionPlan = (formData, uId) => {
  const url = `/api/v1/users/${uId}/subscriptions`;

  return async dispatch => {
    try {
      dispatch({ type: __.SUBSCRIPTION_PLAN });
      const res = await postRequest(url, formData);

      const html = `
        <form method="${res.data.method}" action="${res.data.action}">
          ${res.data.inputs
            .map(
              input => `
            <input name="${input.name}" value="${input.value}">
          `
            )
            .join('')}
          <input type="submit" value="Submit">
        </form>
      `;

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      const form = doc.querySelector('form');

      document.body.appendChild(form);

      form.submit();
      form.remove();
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSubscriptionInfo = uId => {
  const url = `/api/v1/users/${uId}/subscriptions`;

  return async dispatch => {
    dispatch({ type: __.FCH_SUBSCRIPTION_PLAN });
    const res = await getRequest(url);
    dispatch(getSubscriptionInfoDone(res.data));
  };
};

export const getSubscriptionInfoDone = info => {
  return { type: __.FCH_SUBSCRIPTION_PLAN_DONE, payload: { info } };
};

export const cancelSubscription = uId => {
  const url = `/api/v1/users/${uId}/subscriptions`;

  const body = {
    status: 'SUSPEND',
  };

  return async dispatch => {
    try {
      dispatch({ type: __.CANCEL_SUBSCRIPTION });
      await patchRequest(url, body);
      dispatch({ type: __.CANCEL_SUBSCRIPTION_DONE });
    } catch (error) {
      console.log(error);
    }
  };
};

export const cancelSubscriptionDone = () => {
  return { type: __.CANCEL_SUBSCRIPTION_DONE };
};
