import Div from '@jumbo/shared/Div';
import { Divider, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { fchStatusList } from 'app/redux/actions/search';
import { cngEndDate, cngStartDate } from 'app/redux/actions/status';
import StatusList from 'app/shared/StatusList';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const errorMsg = {
  0: '',
  1: '結束日期不可大於開始日期',
  2: '結束日期不可大於今天',
  3: '開始日期不可大於結束日期',
};

const NoteStatusPage = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const [error, setError] = useState(0);
  const [cTime, setCtime] = useState(10);
  const [page, setPage] = useState(1);

  const uId = useSelector(({ app }) => app.user.id);
  const statusList = useSelector(({ search }) =>
    search.getIn(['results', 'statusList'])
  );
  const start_date = useSelector(({ status }) => status.get('start_date'));
  const end_date = useSelector(({ status }) => status.get('end_date'));

  const status_count = useSelector(({ search }) => search.get('status_count'));

  const handleStatusChange = e => {
    const task_status = e.target.value;
    setSearchParams({ status: task_status });
  };

  const handleCngStartDate = date => {
    if (moment(date).diff(moment(end_date)) > 0) return setError(1);
    dispatch(cngStartDate(moment(date).format('YYYY-MM-DD')));
    setError(0);
  };

  const handleCngEndDate = date => {
    if (moment(date).diff(moment()) > 0) return setError(2);
    if (moment(date).diff(moment(start_date)) < 0) return setError(3);
    dispatch(cngEndDate(moment(date).format('YYYY-MM-DD')));
    setError(0);
  };

  const fchMore = () => {
    setPage(page + 1);
    dispatch(fchStatusList(uId, start_date, end_date, 20, page + 1));
  };

  const statusListMemo = useMemo(() => statusList, [statusList]);

  useEffect(() => {
    dispatch(fchStatusList(uId, start_date, end_date));
  }, [uId, start_date, end_date]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCtime(cTime - 1);
    }, 1000);
    if (cTime < 0) {
      dispatch(fchStatusList(uId, start_date, end_date, 10, 1, true));
      setCtime(10);
    }
    return () => clearInterval(interval);
  }, [cTime]);

  return (
    <Div sx={{ p: 2 }}>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent='space-between'
        alignItems='flex-start'>
        <Stack
          gap={{ md: 4, xs: 2 }}
          flexDirection='row'
          alignItems='flex-start'
          mb={{ md: 0, xs: 1 }}>
          <Stack>
            <Stack flexDirection='row'>
              <DatePicker
                disableFuture
                format='YYYY/MM/DD'
                value={start_date ? moment(start_date) : null}
                onChange={handleCngStartDate}
                slotProps={{
                  textField: {
                    error: error > 0,
                  },
                }}
              />
              <Typography
                variant='h4'
                component='h2'
                sx={{ mx: 2, alignSelf: 'center' }}>
                -
              </Typography>
              <DatePicker
                disableFuture
                format='YYYY/MM/DD'
                value={end_date ? moment(end_date) : null}
                onChange={handleCngEndDate}
                slotProps={{
                  textField: {
                    error: error > 0,
                  },
                }}
              />
            </Stack>
            <Typography color='red' sx={{ my: 0, mt: 1 }}>
              {errorMsg[error]}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          variant='h4'
          component='h2'
          sx={{ alignSelf: { md: 'center', xs: 'left' } }}>
          {`${t('REFRESH')}：${cTime} 秒`}
        </Typography>
      </Stack>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <StatusList
        dataSource={statusListMemo}
        status_count={status_count}
        fchMore={fchMore}
      />
    </Div>
  );
};

export default NoteStatusPage;
