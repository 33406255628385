import { Box, Collapse, Divider, Grid, Typography } from '@mui/material';
import {
  delNoteLabel,
  shareNote,
  updNoteContent,
  updNoteLabel,
  updNoteTitle,
  updNoteURL,
} from 'app/redux/actions/note';
import { fchNote } from 'app/redux/actions/search';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom/dist';
import { debounce } from 'lodash';

import ContentEditor from 'app/shared/Editor';
import { createLabel } from 'app/redux/actions/label';

import useAlert from 'app/hooks/useAlert';
import ConfirmModal from 'app/shared/ConfirmModal';
import MediaPlayer from 'app/shared/MediaPlayer';
import useJumboLayout from '@jumbo/hooks/useJumboLayout';
import TitleArea from 'app/shared/Form/TitleArea';
import IDArea from 'app/shared/Form/IDArea';
import LabelArea from 'app/shared/Form/LabelArea';
import TagArea from 'app/shared/Form/TagArea';
import DateArea from 'app/shared/Form/DateArea';
import ActionArea from 'app/shared/Form/ActionArea';

const NoteEditPage = () => {
  const dispatch = useDispatch();
  const { noteId } = useParams();
  const [searchParams] = useSearchParams();
  const modalType = searchParams.get('modal');
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const { setJumboLayoutOptions } = useJumboLayout();

  const [note, setNote] = useState(null);
  const [labelOption, setLabelOption] = useState(null);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [isImmersive, setIsImmersive] = useState(false);

  const userId = useSelector(({ app }) => app.user.id);
  const labelList = useSelector(({ search }) =>
    search.getIn(['results', 'labels'])
  );
  const selectedLabel = useSelector(({ label }) => label.get('list'));
  const config = useSelector(({ app }) => app.user.config);

  const fchData = async () => {
    const data = await dispatch(fchNote(userId, noteId));
    setNote(data);
  };

  const handleUpdate = async (e, value) => {
    if (e.code === 'Enter') {
      const isDuplicated = labelList.some(item => item.name === e.target.value);
      if (isDuplicated) return showErrorAlert('重複的標籤名稱');
      return dispatch(
        createLabel(userId, e.target.value, note.id, selectedLabel)
      );
    }

    const labels = value.map(label => label.id);
    return dispatch(updNoteLabel(userId, note.id, labels));
  };

  const handleContentDurationChange = editor => {
    const content = editor.getText();
    const htmlContent = JSON.stringify(editor.getJSON());

    return dispatch(updNoteContent(userId, note.id, content, htmlContent));
  };

  const handleTitleChange = e => {
    setNote({ ...note, title: e.target.value });
    debounceUpdateTitle(userId, note.id, e.target.value);
  };

  const debounceUpdateTitle = useCallback(
    debounce((userId, noteId, value) => {
      dispatch(updNoteTitle(userId, noteId, value));
    }, 500),
    []
  );

  const handleUrlChange = e => {
    setNote({ ...note, url: e.target.value });
    debounceUpdateUrl(userId, note.id, e.target.value);
  };

  const debounceUpdateUrl = useCallback(
    debounce((userId, noteId, value) => {
      dispatch(updNoteURL(userId, noteId, value));
    }, 500),
    []
  );

  const handleDelLabel = () => {
    dispatch(delNoteLabel(labelOption.user_id, labelOption.id));
    handleDelModalClose();
  };

  const handleDelModalOpen = (e, option) => {
    e.stopPropagation();
    e.preventDefault();

    setLabelOption(option);
    setDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setDelModalOpen(false);
    setLabelOption(null);
  };

  const handleSwitchImmersive = useCallback((e, checked) => {
    setIsImmersive(checked);
    if (window) {
      const delay = setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 300);

      return clearTimeout(delay);
    }
  }, []);

  useEffect(() => {
    if (noteId) {
      fchData();
      window.scrollTo(0, 0);
    }
    return () => {
      setNote(null);
    };
  }, [noteId]);

  useEffect(() => {
    if (modalType === 'download' && note) {
      dispatch(shareNote(note, 'TXT', labelList));
      return showSuccessAlert('檔案已下載');
    }
  }, [modalType, note]);

  useEffect(() => {
    setJumboLayoutOptions({ footer: { hide: true } });
  }, []);

  if (!note) return null;

  return (
    <Box id='note-page-body' component='div' >
      <Grid container sx={{ width: '100%' }} >
        <TitleArea note={note} handleChange={handleTitleChange} />
        <Collapse in={!isImmersive} sx={{ width: '100%' }}>
          <IDArea id={note.id} />
          <DateArea created_at={note.created_at} />
          <LabelArea
            handleUpdate={handleUpdate}
            labelList={labelList}
            selectedLabel={selectedLabel}
          />
          <ActionArea url={note.url} uId={userId} nId={note.id} />
        </Collapse>
      </Grid>

      <Divider sx={{ mt: isImmersive ? 0 : 2 }} />

      {note.source.type === 'audio' && (
        <MediaPlayer
          userId={userId}
          noteId={noteId}
          content={note.content}
          url={note.fileurl}
          isImmersive={isImmersive}
          handleSwitchImmersive={handleSwitchImmersive}
        />
      )}

      {note.source.type !== 'audio' && (
        <ContentEditor
          note={note}
          noteId={noteId}
          onDebouncedUpdate={handleContentDurationChange}
          debounceDuration={1500}
          locales={config && config.lang}
        />
      )}

      <ConfirmModal
        title='警告'
        isOpen={delModalOpen}
        handleClose={handleDelModalClose}
        handleSubmit={handleDelLabel}>
        <Typography
          id='transition-modal-title'
          variant='h4'
          component='h2'
          sx={{ mb: 4, textAlign: 'center' }}>
          確認刪除此標籤 <strong>{labelOption?.name}</strong> ？
        </Typography>
        <Typography
          id='transition-modal-title'
          variant='h4'
          component='h2'
          sx={{ mb: 4, textAlign: 'center' }}>
          刪除此標籤後，其餘包含此標籤的文章將會同步移除此標籤
        </Typography>
      </ConfirmModal>
    </Box>
  );
};

export default NoteEditPage;
