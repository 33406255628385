import Div from '@jumbo/shared/Div';
import { Card, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from 'react-virtualized';
import 'react-virtualized/styles.css';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 151,
  minHeight: 151,
});

const SRTCard = ({
  dataSet,
  currentTime,
  handleChangeText,
  isImmersive,
  scrollToRef,
  scrollToIndex,
}) => {
  const containerRef = useRef(null);

  const isMdMatch = useMediaQuery(theme => theme.breakpoints.down('md'));

  const isViewPage = window.location.pathname.includes('/view/');

  const renderRow = ({ index, key, style, parent }) => {
    const isCurrentTimeInRange =
      currentTime >= dataSet[index].startSeconds &&
      currentTime <= dataSet[index].endSeconds;

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}>
        {({ measure, registerChild }) => (
          <Div ref={registerChild} onLoad={measure} sx={{ ...style }}>
            <Card
              id={dataSet[index].id - 1}
              component='div'
              ref={isCurrentTimeInRange ? scrollToRef : null}
              sx={{
                borderLeftWidth: 8,
                borderLeftStyle: 'solid',
                borderLeftColor: isCurrentTimeInRange ? '#7352c7' : 'grey.300',
              }}>
              <Div key={index} sx={{ p: 2 }}>
                <Typography variant='h5' sx={{ mb: 2 }}>
                  {`${dataSet[index].id}.`}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    mb: 2,
                    color: isCurrentTimeInRange ? '#7352c7' : '#c0c0c0',
                  }}>
                  {dataSet[index].startTime} - {dataSet[index].endTime}
                </Typography>
                {isViewPage ? (
                  <Typography variant='h4'>{dataSet[index].text}</Typography>
                ) : (
                  <TextField
                    multiline
                    maxRows={3}
                    id={dataSet[index].id}
                    fullWidth
                    value={dataSet[index].text}
                    size='small'
                    onChange={handleChangeText}
                  />
                )}
              </Div>
            </Card>
          </Div>
        )}
      </CellMeasurer>
    );
  };

  const dynamicHeight = ({ index }) => {
    const normalizedStr = dataSet[index].text.normalize();
    const charArray = Array.from(normalizedStr);
    const strLen = charArray.length;
    let baseHeight = 161;
    let displayLen = 26;
    if (isViewPage) displayLen = 22;

    const isHasNewLine = dataSet[index].text.includes('\n');

    if (isHasNewLine) {
      baseHeight += 20;
    }

    if (isMdMatch) {
      if (!isHasNewLine) {
        if (strLen > displayLen && strLen < displayLen * 2) {
          baseHeight += 20;
        }
        if (strLen > displayLen * 2) {
          baseHeight += 40;
        }
      }
      if (isHasNewLine) {
        baseHeight += 0;
      }
    }
    return baseHeight;
  };

  useEffect(() => {
    if (scrollToRef.current && !isImmersive) {
      scrollToRef.current.scrollIntoView({
        block: isImmersive ? 'start' : 'center',
        inline: 'center',
      });
    }
  }, [scrollToIndex, scrollToRef.current]);

  return (
    <WindowScroller ref={containerRef} scrollElement={window}>
      {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
        <Div sx={{ flex: '1 1 auto' }}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Div ref={registerChild}>
                <List
                  ref={el => {
                    window.listEl = el;
                  }}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
                  height={isImmersive ? 200 : height}
                  autoHeight={!isImmersive}
                  isScrolling={isScrolling}
                  onScroll={({ scrollTop }) =>
                    onChildScroll(
                      isImmersive ? { scrollTop: 0 } : { scrollTop }
                    )
                  }
                  overscanRowCount={10}
                  rowCount={dataSet.length}
                  rowHeight={isImmersive ? 200 : dynamicHeight}
                  rowRenderer={renderRow}
                  scrollToIndex={scrollToIndex}
                  scrollTop={isImmersive ? null : scrollTop}
                  scrollToAlignment='center'
                  width={width}
                />
              </Div>
            )}
          </AutoSizer>
        </Div>
      )}
    </WindowScroller>
  );
};

export default React.memo(SRTCard);
