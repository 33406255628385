import { Box, Button, Typography } from '@mui/material';
import useLiff from 'app/hooks/useLiff';
import Loading from 'app/shared/Loading';
import { useEffect } from 'react';

const LiffLoginSuccess = () => {
  const liffId =
    process.env.REACT_APP_LIFF_ENV === 'development'
      ? '1661300442-2A9N9jA9'
      : '2000230437-G3mzEmKE';

  const { isReady, liff, detectDevice } = useLiff(liffId);

  const device = detectDevice();

  const handleCloseWindow = () => {
    liff.closeWindow();
  };

  const sendLoginConfirm = async () => {
    const timeout = setTimeout(() => {
      if (device === 'PC') {
        window.location.href = '/';
      } else {
        handleCloseWindow();
      }
    }, 3000);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    sendLoginConfirm();
  }, [isReady]);

  if (!isReady) return <Loading />;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '68vh',
        flexDirection: 'column',
      }}>
      <Typography variant='h1' sx={{ mb: 2 }}>
        身份驗證成功！
      </Typography>
      <Typography
        sx={{ mb: 2 }}
        variant='h6'
        textAlign='center'
        fontSize={12}
        color='#999'>
        {`${device === 'PC' ? '即將跳轉至首頁。' : '視窗將在三秒後關閉。'}`}
      </Typography>
      <Button variant='contained' onClick={handleCloseWindow}>
        關閉視窗
      </Button>
    </Box>
  );
};

export default LiffLoginSuccess;
