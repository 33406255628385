import Div from '@jumbo/shared/Div';
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { refreshNoteStatus } from 'app/redux/actions/search';
import { noteTypeDict } from 'app/utils/constants/noteType';
import statusSchedule from 'app/utils/statusSchedule';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from '../ConfirmModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAlert from 'app/hooks/useAlert';
import { delNoteStatus } from 'app/redux/actions/status';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StatusCard = ({ note, lastIndex }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showSuccessAlert } = useAlert();
  const isMdMatch = useMediaQuery(theme => theme.breakpoints.up('md'));

  const [delModalOpen, setDelModalOpen] = useState(false);

  const userId = useSelector(({ app }) => app.user.id);

  function Progress({ status }) {
    if (!statusSchedule[status]) return null;

    return (
      <Div
        sx={{
          position: 'relative',
          display: 'inline-flex',
          alignSelf: 'center',
          maxWidth: { md: '100%', xs: 54 },
        }}>
        <CircularProgress
          size={isMdMatch ? 92 : 54}
          variant='determinate'
          value={Number(statusSchedule[status].value)}
        />
        <Div
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography
            variant='caption'
            component='div'
            color='text.secondary'
            fontSize={{ md: 24, xs: 14 }}>
            {`${Math.round(statusSchedule[status].value)}%`}
          </Typography>
        </Div>
      </Div>
    );
  }

  const handleRefresh = e => {
    e.stopPropagation();
    dispatch(refreshNoteStatus(userId, note.id, note.status));
  };

  let title = note.title;

  if (!note.title) {
    const date = moment.unix(note?.created_at).format('YYYY-MM-DD');
    const titleText = note?.content.substring(0, 20).replace(/\n/g, '') + '...';
    const type = t(noteTypeDict[note?.source.type]);
    title = `${type} - ${date} - ${titleText}`;
  }

  const handleDelete = (uid, nid) => {
    dispatch(delNoteStatus(uid, nid));
    handleDelModalClose();
    return showSuccessAlert('已成功刪除文章');
  };

  const handleDelModalOpen = (nid, e) => {
    e.stopPropagation();
    setDelModalOpen(true);
  };

  const handleDelModalClose = () => {
    setDelModalOpen(false);
  };

  return (
    <>
      <Card component='div' sx={{ display: 'flex', p: 3, mb: 0 }}>
        <Stack
          justifyContent='space-between'
          flexDirection={{ md: 'row', xs: 'column' }}
          width='100%'
          alignItems={{ md: 'center', xs: 'flex-start' }}>
          <Stack
            flexDirection='row'
            alignItems='flex-start'
            justifyContent='flex-start'
            gap={{ md: 4, xs: 2 }}
            mb={{ md: 0, xs: 1 }}>
            <Progress status={note.status} />

            <Stack justifyContent='space-between' alignItems='flex-start'>
              <Stack gap={1} flexDirection='row'>
                <Chip
                  label={t(noteTypeDict[note.source.type])}
                  size='small'
                  sx={{ mb: 1.5 }}
                />
                {note.status === 'FAILED' && (
                  <Typography color='error'>FAILED</Typography>
                )}
              </Stack>
              {note.status !== 'DONE' && note.status !== 'FAILED' && (
                <Typography sx={{ mb: 1 }}>
                  {t('NOTE_REMAINING_TIMES')}：
                  <Typography component='span' sx={{ fontWeight: '500' }}>
                    {note.remainingMins < 0
                      ? '重新預估處理時間中，請稍待一下'
                      : `${note.remainingMins} ${t('MINUTES')}`}
                  </Typography>
                </Typography>
              )}
              <Typography sx={{ mb: 1, fontSize: { md: 14, xs: 12 } }}>
                {t('NOTE_TITLE')}：
                <Typography component='span' sx={{ fontWeight: '500' }}>
                  {title}
                </Typography>
              </Typography>
              {note.source.task_type && (
                <Typography sx={{ mb: 1, fontSize: { md: 14, xs: 12 } }}>
                  {t('NOTE_TASK_TYPE')}：
                  <Typography component='span' sx={{ fontWeight: '500' }}>
                    {t(note.source.task_type.toUpperCase())}
                  </Typography>
                </Typography>
              )}
              <Typography sx={{ mb: 1, fontSize: { md: 14, xs: 12 } }}>
                {t('NOTE_CREATED_AT')}：
                <Typography component='span' sx={{ fontWeight: '500' }}>
                  {moment.unix(note.created_at).format('YYYY-MM-DD HH:mm:ss')}
                </Typography>
              </Typography>
              {!isMdMatch && (
                <Stack
                  width={{ md: 'initial', xs: '100%' }}
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  flexDirection='row'
                  gap={1}>
                  {note.status === 'DONE' && (
                    <Button
                      variant='contained'
                      color='info'
                      startIcon={<VisibilityIcon />}
                      onClick={() => navigate(`/note/edit/${note.id}`)}>
                      {t('NOTE_VIEW')}
                    </Button>
                  )}
                  {note.status !== 'DONE' && note.status !== 'FAILED' && (
                    <Button
                      variant='contained'
                      color='inherit'
                      startIcon={<RefreshIcon />}
                      onClick={handleRefresh}>
                      {t('REFRESH')}
                    </Button>
                  )}
                  {note.status === 'FAILED' && (
                    <Button
                      variant='contained'
                      color='secondary'
                      startIcon={<DeleteIcon />}
                      onClick={e => handleDelModalOpen(note.id, e)}>
                      {t('DEL_TASK')}
                    </Button>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
          {isMdMatch && (
            <Stack
              width={{ md: 'initial', xs: '100%' }}
              justifyContent='flex-end'
              alignItems='flex-end'
              flexDirection='row'
              gap={1}>
              {note.status === 'DONE' && (
                <Button
                  variant='contained'
                  color='info'
                  size={isMdMatch ? 'medium' : 'small'}
                  startIcon={<VisibilityIcon />}
                  onClick={() => navigate(`/note/edit/${note.id}`)}>
                  {t('NOTE_VIEW')}
                </Button>
              )}
              {note.status !== 'DONE' && note.status !== 'FAILED' && (
                <Button
                  variant='contained'
                  color='inherit'
                  size={isMdMatch ? 'medium' : 'small'}
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}>
                  {t('REFRESH')}
                </Button>
              )}
              {note.status === 'FAILED' && (
                <Button
                  variant='contained'
                  color='secondary'
                  size={isMdMatch ? 'medium' : 'small'}
                  startIcon={<DeleteIcon />}
                  onClick={e => handleDelModalOpen(note.id, e)}>
                  {t('DEL_TASK')}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Card>
      <ConfirmModal
        isOpen={delModalOpen}
        title={t('MODAL_TITLE_WARNING')}
        content={t('MODAL_DELETE_NOTE')}
        handleClose={handleDelModalClose}
        handleSubmit={() => handleDelete(userId, note.id)}
        okText={t('MODAL_CONFIRM')}
        cancelText={t('MODAL_CANCEL')}
      />
    </>
  );
};

export default StatusCard;
