import { CircularProgress } from '@mui/material';
import Div from '@jumbo/shared/Div';

const Loading = () => {
  return (
    <Div
      sx={{
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
        width: '100%',
        zIndex: 9999,
      }}>
      <CircularProgress sx={{ m: '0 auto' }} />
    </Div>
  );
};

export default Loading;