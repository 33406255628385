import Div from '@jumbo/shared/Div';
import {
  Container,
  Typography,
  Box,
  IconButton,
  Link,
  List,
  ListItemText,
  useScrollTrigger,
  Fab,
  Fade,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import HomeIcon from '@mui/icons-material/Home';
import useLiff from 'app/hooks/useLiff';
import Loading from 'app/shared/Loading';

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#appbar'
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role='presentation'
        sx={{ position: 'fixed', bottom: 32, right: 32 }}>
        {children}
      </Box>
    </Fade>
  );
}

const LiffTutorial = props => {
  const liffId =
    process.env.REACT_APP_LIFF_ENV === 'development'
      ? '1661300442-1z0A0eM0'
      : '2000230437-XLk2Yk3Y';

  const { isReady } = useLiff(liffId);

  const LinkList = ({ text, href }) => {
    let pl = 0;

    if (/[0-9]\./.test(text)) pl = 0;
    if (/[0-9]\.[0-9]/.test(text)) pl = 2;
    if (/[0-9]\.[0-9]\.[0-9]/.test(text)) pl = 5;

    const scrollTo = () => {
      const anchor = document.querySelector(href);
      if (anchor) {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    return (
      <ListItemText sx={{ pl }}>
        <Link
          sx={{
            textDecoration: 'none',
            color: '#475259',
          }}
          href={href}>
          {text}
        </Link>
      </ListItemText>
    );
  };

  const ImgBoxStyle = { mb: 2, display: 'flex', justifyContent: 'center' };

  if (!isReady) return <Loading />;

  return (
    <>
      <Div
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          px: 3,
          py: 2,
        }}>
        <Box
          id='appbar'
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img
            src='/images/logo-main.png'
            alt='logo'
            style={{ width: '64px', height: '64px' }}
          />
          <Typography
            variant='h1'
            color='primary'
            sx={{ position: 'relative', top: 4 }}>
            AIMochi
          </Typography>
        </Box>

        <IconButton sx={{ boxShadow: 'rgba(0, 0, 0, 0.086) 0px 5px 10px' }}>
          <Link href='https://www.aimochi.ai/' target='_blank'>
            <HomeIcon
              sx={{
                fontSize: '36px',
                transform: 'scaleX(-1)',
                cursor: 'pointer',
                color: '#475259',
              }}
            />
          </Link>
        </IconButton>
      </Div>

      <Container maxWidth='lg' sx={{ px: 3, py: 3 }}>
        <Div
          sx={{
            bgcolor: '#fff',
            boxShadow: '0 0px 5px 0px rgba(0, 0, 0, 0.1)',
            px: 5,
            py: 4,
          }}>
          <Div>
            <Typography variant='h1'>目錄</Typography>
            <List>
              <LinkList text='1. 簡介' href='#1-' />
              <LinkList text='2. 使用AIMochi記錄各種資料' href='#2-' />
              <LinkList text='2.1 製作語音筆記 (手機版使用者)' href='#2-1-' />
              <LinkList text='2.1.1 使用Line內建錄音功能' href='#2-1-1' />
              <LinkList text='2.1.2 使用其他錄音應用程式' href='#2-1-2' />
              <LinkList text='2.1.3 上傳已有音檔 - 分享檔案' href='#2-1-3' />
              <LinkList text='2.1.4 上傳已有音檔 - Line Keep' href='#2-1-4' />
              <LinkList
                text='2.1.5 上傳已有音檔 - 電腦版使用者'
                href='#2-1-5'
              />
              <LinkList text='2.2 製作圖片筆記' href='#2-2-' />
              <LinkList text='2.3 製作連結筆記' href='#2-3-' />
              <LinkList text='2.4 製作文字筆記' href='#2-4-' />
              <LinkList
                text='2.5 製作檔案筆記（包括PDF、DOCX等）'
                href='#2-5-'
              />
              <LinkList text='2.5.1 上傳檔案步驟 - 分享檔案' href='#2-5-1' />
              <LinkList text='2.5.2 上傳檔案步驟 - Line Keep' href='#2-5-2' />
              <LinkList
                text='2.5.3 上傳已有檔案 - 電腦版使用者'
                href='#2-5-3'
              />
              <LinkList text='3. 筆記檢視與管理' href='#3-' />
              <LinkList text='3.1 檢視筆記' href='#3-1-' />
              <LinkList text='3.2 編輯筆記' href='#3-2-' />
              <LinkList text='3.3 打開連結' href='#3-3-' />
              <LinkList text='3.4 智慧編輯' href='#3-4-' />
              <LinkList text='4. 智慧編輯功能' href='#4-' />
              <LinkList text='4.1 多語言翻譯' href='#4-1-' />
              <LinkList text='4.2 摘要生成' href='#4-2-' />
              <LinkList text='4.3 筆記潤飾' href='#4-3-' />
              <LinkList text='4.4 尋找相似筆記' href='#4-4-' />
              <LinkList text='4.5 問問ChatGPT' href='#4-5-' />
              <LinkList text='4.6 輸出內容' href='#4-6-' />
              <LinkList text='4.6.1 一般筆記輸出' href='#4-6-1' />
              <LinkList text='4.6.2 語音筆記輸出' href='#4-6-2' />
              <LinkList text='4.7 打開原連結 (只適用於連結筆記)' href='#4-7-' />
              <LinkList text='4.8 在AIMochi 編輯' href='#4-8-' />
              <LinkList text='4.9 回報問題' href='#4-9-' />
              <LinkList text='5. 智慧搜尋與問答' href='#5-' />
              <LinkList text='5.1 關鍵字搜尋' href='#5-1-' />
              <LinkList text='5.2 智慧搜尋' href='#5-2-' />
              <LinkList text='5.3 向筆記問答' href='#5-3-' />
            </List>

            <Box sx={{ mb: 2 }}>
              <Typography variant='h2' id='1-'>
                1. 簡介
              </Typography>
              <Typography component='p'>
                這是所有記憶魔法開始的地方。AIMochi是一個創新的AI助手，它可以協助您記錄各種資料，無論是文字、連結、語音、檔案還是圖片，只需透過Line傳給AIMochi，它就像是您的第二個腦，可以幫助您輕鬆記錄並隨時檢索筆記。
              </Typography>
              <br />
              <Typography component='p'>
                此外，AIMochi具備強大的人工智慧功能，您可以讓它為你執行翻譯、筆記摘要、逐字稿轉換，甚至進行深入的對話。不論你是學生、專業商業人士還是創意工作者，AIMochi都能迅速從你的記憶中提煉出有價值的資訊，節省寶貴時間，提高您的工作效率。
              </Typography>
            </Box>
            <Box>
              <Typography variant='h2' id='2-' sx={{ mb: 3 }}>
                2. 使用AIMochi記錄各種資料
              </Typography>

              <Typography variant='h4' id='2-1-'>
                2.1 製作語音筆記 (手機版使用者)
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                無論是重要的會議討論還是日常工作點滴，您都可以通過AIMochi輕鬆錄製並轉化為文字記錄。完成錄音後，只需上傳至AIMochi平台，我們的先進語音轉文字技術便會將其轉換成清晰、易讀的文字格式。
              </Typography>

              <Typography variant='h5' id='2-1-1' sx={{ mb: 2 }}>
                2.1.1 使用Line內建錄音功能
              </Typography>

              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.1 使用Line內建錄音功能.webp'
                  alt='2.1.1 使用Line內建錄音功能'
                />
              </Box>

              <Typography variant='h5' id='2-1-2' sx={{ mb: 2 }}>
                2.1.2 使用其他錄音應用程式
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.2 使用其他錄音應用程式.webp'
                  className='responsive'
                  alt='2.1.2 使用其他錄音應用程式'
                />
              </Box>

              <Typography variant='h5' id='2-1-3'>
                2.1.3 上傳已有音檔 - 分享檔案
              </Typography>

              <Typography component='p' sx={{ mb: 2 }}>
                在Line中創建空群組，將檔案傳送至該群組，再透過分享功能傳送至AIMochi。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.3 上傳已有音檔_a.webp'
                  className='responsive'
                  alt='透過分享功能傳送至AIMochi'
                />
              </Box>

              <Typography variant='h5' id='2-1-4'>
                2.1.4 上傳已有音檔 - Line Keep
              </Typography>

              <Typography component='p' sx={{ mb: 2 }}>
                1. 使用Line的Keep功能，先將檔案上傳至空群組，再保存至Keep
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.3 上傳已有音檔_b1.webp'
                  className='responsive'
                  alt='將檔案上傳至空群組，再保存至Keep'
                />
              </Box>

              <Typography component='p' sx={{ mb: 2 }}>
                2. 於AIMochi Line App 透過 Keep 分享檔案至 AIMochi
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.3 上傳已有音檔_b2.webp'
                  className='responsive'
                  alt='透過 Keep 分享檔案至 AIMochi'
                />
              </Box>

              <Typography variant='h5' id='2-1-5'>
                2.1.5 上傳已有音檔 - 電腦版使用者
              </Typography>

              <Typography component='p'>
                <em>由於桌機版Line不支持直接檔案上傳，建議採用以下方法：</em>
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                1.
                將檔案上傳至Line空群組，使用Line的Keep功能，先將檔案保存至Keep
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.2.1 製作語音筆記操作步驟_1.webp'
                  className='responsive'
                  alt='使用Line的Keep功能，先將檔案保存至Keep'
                />
              </Box>
              <Typography component='p' sx={{ mb: 2 }}>
                2. 於AIMochi Line App 透過 Keep 分享檔案至 AIMochi
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.2.1 製作語音筆記操作步驟_2.webp'
                  className='responsive'
                  alt='透過 Keep 分享檔案至 AIMochi'
                />
              </Box>

              <Typography variant='h4' id='2-2-'>
                2.2 製作圖片筆記
              </Typography>

              <Typography component='p' sx={{ mb: 2 }}>
                透過光學字元識別（OCR）技術，AIMochi能夠精確識別圖片中的文字，並將其轉化成結構化的文字筆記。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.3.1 圖片識別操作步驟.webp'
                  className='responsive'
                  alt='2.3.1 圖片識別操作步驟'
                />
              </Box>

              <Typography variant='h4' id='2-3-'>
                2.3 製作連結筆記
              </Typography>
              <Typography component='p' sx={{ mb: 1 }}>
                通過AIMochi的連結筆記功能，您可以將網絡連結轉化為結構化的個人筆記。我們的系統會自動擷取連結中的主要內容。
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                1. 直接將連結貼上AIMochi
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.4.1 製作連結筆記步驟_1.webp'
                  className='responsive'
                  alt='將連結貼上AIMochi'
                />
              </Box>

              <Typography component='p' sx={{ mb: 2 }}>
                2. 利用分享功能分享連結至AIMochi
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.4.1 製作連結筆記步驟_2.webp'
                  className='responsive'
                  alt='分享連結至AIMochi'
                />
              </Box>

              <Typography variant='h4' id='2-4-'>
                2.4 製作文字筆記
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                將您的工作計劃、會議記錄或日常想法轉化為文字筆記，僅需簡單幾步。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.5.1 製作文字筆記步驟.webp'
                  className='responsive'
                  alt='2.5.1 製作文字筆記步驟'
                />
              </Box>

              <Typography variant='h4' id='2-5-'>
                2.5 製作檔案筆記（包括PDF、DOCX等）
              </Typography>
              <Typography component='p' sx={{ mb: 1 }}>
                將語音檔、影片檔或PDF從電腦上傳至AIMochi，以便利用其筆記生成功能。
              </Typography>

              <Typography variant='h5' id='2-5-1'>
                2.5.1 上傳檔案步驟 - 分享檔案
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                在Line中創建空群組，將檔案傳送至該群組，再透過分享功能傳送至AIMochi。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.3 上傳已有音檔_a.webp'
                  className='responsive'
                  alt='將檔案傳送至該群組，再透過分享功能傳送至AIMochi'
                />
              </Box>

              <Typography variant='h5' id='2-5-2'>
                2.5.2 上傳檔案步驟 - Line Keep
              </Typography>
              <Typography component='p'>1. 先將檔案保存至Keep</Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.3 上傳已有音檔_b1.webp'
                  className='responsive'
                  alt='先將檔案保存至Keep'
                />
              </Box>
              <Typography component='p'>
                2. 於AIMochi Line App 透過 Keep 分享檔案至 AIMochi
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.1.3 上傳已有音檔_b2.webp'
                  className='responsive'
                  alt='透過 Keep 分享檔案至 AIMochi'
                />
              </Box>

              <Typography variant='h5' id='2-5-3'>
                2.5.3 上傳已有檔案 - 電腦版使用者
              </Typography>

              <Typography component='p'>
                <em>由於桌機版Line不支持直接檔案上傳，建議採用以下方法：</em>
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                1.
                將檔案上傳至Line空群組，使用Line的Keep功能，先將檔案保存至Keep
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.2.1 製作語音筆記操作步驟_1.webp'
                  className='responsive'
                  alt='使用Line的Keep功能，先將檔案保存至Keep'
                />
              </Box>
              <Typography component='p' sx={{ mb: 2 }}>
                2. 於AIMochi Line App 透過 Keep 分享檔案至 AIMochi
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/2.2.1 製作語音筆記操作步驟_2.webp'
                  className='responsive'
                  alt='透過 Keep 分享檔案至 AIMochi'
                />
              </Box>
            </Box>

            <Box>
              <Typography variant='h2' id='3-' sx={{ mb: 3 }}>
                3. 筆記檢視與管理
              </Typography>

              <Typography variant='h3' id='3-1-'>
                3.1 檢視筆記
              </Typography>
              <Typography component='p'>
                在使用者透過AIMochi輸入或上傳資料後，系統將提供一個方便的「查詢筆記」按鈕。這個功能允許使用者快速存取他們的筆記。一旦筆記準備就緒，將在對話界面中顯示一個筆記預覽圖，其中包含筆記的標題、內容以及創建的時間。
              </Typography>
              <Typography component='p'>若筆記已完成，如下圖所示：</Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/3.1 查詢筆記_1.webp'
                  className='responsive'
                  alt='3.1 查詢筆記_1'
                />
              </Box>
              <Typography component='p' sx={{ mb: 2 }}>
                如果筆記仍在處理中，則會出現「尚未完成，請稍後再試」的提示：
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/3.1 查詢筆記_2.webp'
                  className='responsive'
                  alt='3.1 查詢筆記_2'
                />
              </Box>
              <Typography variant='h3' id='3-2-'>
                3.2 編輯筆記
              </Typography>
              <Typography component='p'>
                使用者可直接點選筆記標題，系統將自動導航至AiMochi的編輯頁面(
                <a href='https://app.aimochi.ai'> https://app.aimochi.ai </a>
                )，方便進行快速修改或更新。
              </Typography>
              <Typography component='p'>編輯頁面範例：</Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/3.2 編輯筆記.webp'
                  className='responsive'
                  alt='3.2 編輯筆記'
                />
              </Box>
              <Typography variant='h3' id='3-3-'>
                3.3 打開連結
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                對於包含外部連結的筆記，使用者可以簡單點選以打開該連結，快速訪問相關資訊。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/3.3 打開連結.webp'
                  className='responsive'
                  alt='3.3 打開連結'
                />
              </Box>
              <Typography variant='h3' id='3-4-'>
                3.4 智慧編輯
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                AiMochi還提供智慧編輯功能，讓使用者可以對筆記進行更進階的操作。詳細的智慧編輯功能介紹，請參閱相關章節。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/3.4 智慧編輯.webp'
                  className='responsive'
                  alt='3.4 智慧編輯'
                />
              </Box>
            </Box>

            <Box>
              <Typography variant='h2' id='4-'>
                4. 智慧編輯功能
              </Typography>
              <Typography component='p' sx={{ mb: 1 }}>
                AiMochi的智慧編輯功能提供了一系列先進工具，使您能夠對收集的筆記進行深入分析和處理，包括摘要、翻譯和問答等。
              </Typography>
              <Typography variant='h3' id='4-1-'>
                4.1 多語言翻譯
              </Typography>
              <Typography component='p'>
                AiMochi可以將筆記中的文字內容翻譯成多種語言，包括中文、英文、日文和韓文等。如果您需要將內容翻譯成其他語言，我們也樂意提供協助。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.1 翻譯功能.webp'
                  className='responsive'
                  alt='4.1 翻譯功能'
                />
              </Box>
              <Typography variant='h3' id='4-2-'>
                4.2 摘要生成
              </Typography>
              <Typography component='p'>
                此功能能迅速生成原始筆記內容的摘要，以提高您的閱讀和理解效率。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.2 摘要.webp'
                  className='responsive'
                  alt='4.2 摘要'
                />
              </Box>
              <Typography variant='h3' id='4-3-'>
                4.3 筆記潤飾
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                專為語音和圖片筆記設計，此功能利用ChatGPT的語義理解能力，對內容進行精緻潤飾，改善資料辨識的準確性，並增加適當的標點符號，提升文章的可讀性。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.3 潤飾.webp'
                  className='responsive'
                  alt='4.3 潤飾'
                />
              </Box>
              <Typography variant='h3' id='4-4-'>
                4.4 尋找相似筆記
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                透過語義相似度分析，此功能幫助您在歷史資料中快速找到相似的筆記。
              </Typography>
              <Typography component='p'>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.4尋找相似筆記.webp'
                  className='responsive'
                  alt='4.4尋找相似筆記'
                />
              </Typography>

              <Typography variant='h3' id='4-5-'>
                4.5 問問ChatGPT
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                您可以將筆記和相關問題提交給ChatGPT。ChatGPT將對內容進行深入分析，迅速提供整理過的答案。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.5 問問ChatGPT.webp'
                  className='responsive'
                  alt='4.5 問問ChatGPT'
                />
              </Box>
              <Typography variant='h3' id='4-6-'>
                4.6 輸出內容
              </Typography>
              <Typography component='p' sx={{ mb: 1 }}>
                您可以將筆記內容輸出到Line上。由於Line有顯示長度限制，對於較長的文章，建議在AiMochi的編輯界面上瀏覽。
              </Typography>
              <Typography variant='h5' id='4-6-1'>
                4.6.1 一般筆記輸出
              </Typography>
              <Typography component='p'>
                直接將原始內容輸出到Line上的範例
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.6.1 一般筆記輸出.webp'
                  className='responsive'
                  alt='4.6.1 一般筆記輸出'
                />
              </Box>
              <Typography variant='h5' id='4-6-2'>
                4.6.2 語音筆記輸出
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                語音筆記可以選擇單句條列、合併語句與字幕檔三種格式
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.6.2 語音筆記輸出.webp'
                  className='responsive'
                  alt='4.6.2 語音筆記輸出'
                />
              </Box>
              <Typography component='p' sx={ImgBoxStyle}>
                <strong>a.單句條列</strong>
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                獨立顯示每一句
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.6.2 語音筆記輸出_a.webp'
                  className='responsive'
                  alt='4.6.2 語音筆記輸出_a'
                />
              </Box>
              <Typography component='p' sx={ImgBoxStyle}>
                <strong>b. 合併語句</strong>
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                將同一發言者的內容合併顯示
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.6.2 語音筆記輸出_b.webp'
                  className='responsive'
                  alt='4.6.2 語音筆記輸出_b'
                />
              </Box>
              <Typography component='p'>
                <strong>c.字幕檔</strong>
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                輸出成字幕檔案，附帶時間戳記
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.6.2 語音筆記輸出_c.webp'
                  className='responsive'
                  alt='4.6.2 語音筆記輸出_c'
                />
              </Box>
              <Typography variant='h3' id='4-7-'>
                4.7 打開原連結 (只適用於連結筆記)
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                此功能讓您可以直接從筆記中打開原始連結。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.7 打開原連結.webp'
                  className='responsive'
                  alt='4.7 打開原連結'
                />
              </Box>
              <Typography variant='h3' id='4-8-'>
                4.8 在AIMochi 編輯
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                此功能將引導您進入AiMochi的編輯界面，您可以在此瀏覽或編輯筆記。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.8 在AIMochi 編輯.webp'
                  className='responsive'
                  alt='4.8 在AIMochi 編輯'
                />
              </Box>
              <Typography variant='h3' id='4-9-'>
                4.9 回報問題
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                若您對轉錄結果有疑問，可利用此功能向我們回報，以便我們獲取相關資訊並改善服務。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/4.9 回報問題.webp'
                  className='responsive'
                  alt='4.9 回報問題'
                />
              </Box>
            </Box>

            <Box>
              <Typography variant='h2' id='5-'>
                5. 智慧搜尋與問答
              </Typography>
              <Typography component='p' sx={{ mb: 1 }}>
                AiMochi的智慧搜尋與問答功能旨在提升使用者在眾多筆記中尋找所需資料的便利性。透過這些功能，使用者可以將筆記庫視為個人知識庫或第二大腦，進行高效的資料檢索與問答互動。
              </Typography>
              <Typography variant='h3' id='5-1-'>
                5.1 關鍵字搜尋
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                允許使用者輸入特定關鍵字，系統將自動篩選出與這些關鍵字最相關的筆記。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/5.1 關鍵字搜尋.webp'
                  className='responsive'
                  alt='5.1 關鍵字搜尋'
                />
              </Box>
              <Typography variant='h3' id='5-2-'>
                5.2 智慧搜尋
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                智慧搜尋功能比一般的關鍵字搜尋更進階，支援自然語言查詢。例如，您可以查找特定時間範圍內的語音筆記，或尋找提及特定主題（如人工智慧）的連結筆記。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/5.2 智慧搜尋.webp'
                  className='responsive'
                  alt='5.2 智慧搜尋'
                />
              </Box>
              <Typography variant='h3' id='5-3-'>
                5.3 向筆記問答
              </Typography>
              <Typography component='p' sx={{ mb: 2 }}>
                此功能將您的筆記轉化為一個互動式的知識庫。您可以向它提出任何問題，AiMochi會從您的筆記中找出最相關的內容，並結合大型語言模型來生成答案。
              </Typography>
              <Box component='p' sx={ImgBoxStyle}>
                <img
                  src='https://www.aimochi.ai/static/images/tutorial/5.3 向筆記問答.webp'
                  className='responsive'
                  alt='5.3 向筆記問答'
                />
              </Box>
            </Box>
          </Div>
        </Div>
      </Container>
      <ScrollTop {...props}>
        <Fab color='primary' size='large' aria-label='scroll back to top'>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default LiffTutorial;
