import Immutable from 'immutable';

import * as __ from '../types/subscription';

const INIT_STATE = Immutable.OrderedMap({
  record: [],
});

const subscriptionReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case __.FCH_SUBSCRIPTION_PLAN_DONE:
      return _.set('record', payload.info);
    default:
      return _;
  }
};

export default subscriptionReducer;
