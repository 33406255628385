import React from 'react';
import NoteEditPage from 'app/pages/Note/Edit';
import NoteViewPage from 'app/pages/Note/View';
import NoteStatusPage from 'app/pages/Note/Status';

const noteRoutes = [
  {
    path: '/note/edit/:noteId',
    element: <NoteEditPage />,
  },
  {
    path: '/note/view/:noteId',
    element: <NoteViewPage />,
  },
  {
    path: '/note/status',
    element: <NoteStatusPage />
  }
];

export default noteRoutes;
